<template>
  <div class="sidebar-bg full-width" style="overflow: visible;">
    <div class="nav-grid nav-shadow" style="overflow: visible;">
      <router-link to="/" style="margin-right: auto;">
        <img
          src="@/assets/images/Dele.png"
          style="margin: 8px 25px; max-height: 45px;"
        />
      </router-link>
      <div class="flex-center">
        <div v-if="myFulfillers.length > 0" class="flex-center" style="width: 240px;">
          <v-select
            :value="currFulfiller.name"
            :items="myFulfillers"
            item-text="name"
            return-object
            shaped
            filled
            :disabled="$route.name === 'EditContainer'"
            hide-details="true"
            dense
            label="Fulfiller"
            class="m-2"
            v-on:input="(e) => switchFulfiller(e)"
          >
          </v-select>
        </div>
        <div v-if="myCustomers.length > 0" class="flex-center" style="width: 240px;">
          <v-select
            :value="currCustomer.name"
            :items="myCustomers"
            item-text="name"
            return-object
            shaped
            :disabled="$route.name === 'EditContainer'"
            filled
            hide-details="true"
            dense
            label="Customer"
            class="m-2"
            v-on:input="(e) => switchCustomer(e)"
          >
          </v-select>
        </div>
        <div v-if="getRoots.length > 1 && myInfo" class="flex-center" style="width: 290px;">
          <v-select
            :value="rootContainer.name"
            :items="getRoots"
            item-text="name"
            return-object
            shaped
            filled
            :disabled="$route.name === 'EditContainer'"
            hide-details="true"
            dense
            label="Root Container"
            class="m-2"
            v-on:input="(e) => switchRootContainer(e)"
          >
          </v-select>
        </div>
        <div v-else></div>

      </div>
      <div class="center-flex-end" style="font-size: 11px;">
        {{ myEmail }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'
import localforage from 'localforage'
export default {
  name: 'Topnav',
  data () {
    return {
      rootContainer: {},
      currCustomer: {},
      currFulfiller: {}
    }
  },
  computed: {
    ...mapGetters({
      getRoots: 'containers/getRoots',
      getRootContainer: 'containers/getRootContainer',
      myInfo: 'login/myInfo',
      myCustomers: 'login/myCustomers',
      myFulfillers: 'login/myFulfillers'
    }),
    myEmail () {
      return this.myInfo?.email
    }
  },
  async mounted () {
    const lsKey = this.myInfo?.username + '-Root'
    const lastRoot = await localforage.getItem(lsKey)
    const rootExists = this.getRoots.some(root => root.id === lastRoot?.id)
    if (lastRoot && rootExists) {
      this.switchRootContainer(lastRoot)
    } else {
      this.switchRootContainer(cloneDeep(this.getRoots[0]))
    }
  },
  methods: {
    ...mapActions({
      fetchContainers: 'containers/fetchContainers',
      chooseRootContainer: 'containers/chooseRootContainer',
      chooseCustomer: 'containers/chooseCustomer',
      chooseFulfiller: 'containers/chooseFulfiller',
      switchCustomerOptions: 'containers/switchCustomerOptions'
    }),
    async switchRootContainer (newRoot) {
      const lsKey = this.myInfo.username + '-Root'
      await localforage.setItem(lsKey, cloneDeep(newRoot))
      await this.chooseRootContainer(cloneDeep(newRoot))
      this.rootContainer = cloneDeep(newRoot)
    },
    async switchCustomer (customer) {
      console.log('Customer choosen in top nav', customer)
      this.currCustomer = customer
      const answer = await this.chooseCustomer([customer.id, customer?.clientPropsNew?.clientId])
      await this.fetchContainers(answer)
      const lsKey = this.myInfo.username + '-Customer'
      await localforage.setItem(lsKey, customer)
    },
    async switchFulfiller (fulfiller) {
      if (this.myInfo && this.myInfo.username) {
        console.log('Fulfiler choosen in top nav', fulfiller)
        const customerOptions = await this.switchCustomerOptions(fulfiller)
        this.currFulfiller = fulfiller
        await this.chooseFulfiller(fulfiller)
        customerOptions?.[0] && await this.switchCustomer(customerOptions[0])
        await this.fetchContainers()
        const lsKey = this.myInfo.username + '-Fulfiller'
        await localforage.setItem(lsKey, fulfiller)
      }
    }
  },
  watch: {
    async myCustomers (newVal, oldVal) {
      if (newVal !== oldVal) {
        const lsCus = this.myInfo?.username && this.myInfo.username + '-Customer'
        const lastCustomer = lsCus && await localforage.getItem(lsCus)
        let currCustomer = lastCustomer && newVal.some(cus => cus.id === lastCustomer?.id) ? lastCustomer : newVal.length > 0 ? newVal[0] : {}
        if (!currCustomer && this.myCustomers.length) currCustomer = this.myCustomers[0]
        if (currCustomer) {
          this.currCustomer = currCustomer
          await this.switchCustomer(currCustomer)
          await localforage.setItem(lsCus, currCustomer)
        }
      }
    },
    async getRoots (newVal, oldVal) {
      if (newVal[0]?.id !== oldVal[0]?.id) {
        await this.switchRootContainer(cloneDeep(newVal[0]))
      }
    },
    async myFulfillers (newVal, oldVal) {
      if (this.myInfo && this.myInfo.username) {
        const lsFul = this.myInfo.username + '-Fulfiller'
        const lastFulfiller = await localforage.getItem(lsFul)
        if (lastFulfiller && this.myFulfillers.some(cus => cus.id === lastFulfiller?.id)) {
          await this.switchFulfiller(lastFulfiller)
        } else {
          await this.fetchContainers()
          await this.switchFulfiller(this.myFulfillers[0])
        }
        this.currFulfiller = lastFulfiller && this.myFulfillers.some(cus => cus.id === lastFulfiller?.id) ? lastFulfiller : this.myFulfillers.length > 0 ? this.myFulfillers[0] : {}
      }
    }
  }
}
</script>
<style lang="scss">
.nav-shadow {
  box-shadow: -3px 10px 9px -7px rgba(0, 0, 0, 0.17);
}
.center-flex-end {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 15px;
  font-size: 14px;
}
.flex-center {
  display: flex; flex-direction: row; justify-content: flex-start; align-items: center;
}
</style>
