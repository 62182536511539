<template>
  <article class="edit-rule-community-settings">
    <div class="edit-rule-community-settings__notification">
        <span class='edit-rule-community-settings__subtitle'>{{ $t('notification.notification') }}</span>
        <div class="edit-rule-community-settings__toggle-buttons">
          <b-form-checkbox
            class="colors edit-rule-community-settings__toggle-buttons__toggle-button"
            name="enabled-button"
            v-model="enabledValue"
            @change="updateEnabled({ enabled: $event })"
            switch
            :disabled="loading"
          >
            {{ enabledValue ? 'On' : 'Off' }}
          </b-form-checkbox>
        </div>
      </div>
    <div>
      <span class="edit-rule-community-settings__subtitle">{{
        inBathroomForXTime
          ? $t('notification.bathroomDwellCommunity')
          : $t('notification.timeframeCommunity')
      }}</span
      >&nbsp;
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <i class="fas fa-question-circle" v-on="on" v-bind="attrs"></i>
        </template>
        <span>{{ $t('notification.communitySettingsTooltip') }}</span>
      </v-tooltip>
    </div>
    <div
      v-if="!inBathroomForXTime"
      class="edit-rule-community-settings__toggle-buttons"
    >
      <b-form-checkbox
        class="colors edit-rule-community-settings__toggle-buttons__toggle-button"
        name="daytime-check-button"
        v-model="daytimeValue"
        :disabled="loading || !enabledValue"
        @change="update({ daytime: $event })"
        switch
      >
        {{ $t('notification.daytimeCommunity') }}
      </b-form-checkbox>
      <b-form-checkbox
        class="colors edit-rule-community-settings__toggle-buttons__toggle-button"
        name="night-time-check-button"
        v-model="nightTimeValue"
        :disabled="loading || !enabledValue"
        @change="update({ nighttime: $event })"
        switch
      >
        {{ $t('notification.nightTimeCommunity') }}
      </b-form-checkbox>
    </div>
    <div v-else class="edit-rule-community-settings__dwell-time">
      <BathroomDwellTime :dwellTime="dwellTime" :disabled="!enabledValue" :rule="rule" :community="true" :setLoading="setLoading" :loading="loading"/>
      <v-btn
        text
        class="edit-rule-community-settings__dwell-time__reset-button"
        type="button"
        @click.stop="confirmResettingDwellTime"
      >
        {{ $t('buttons.reset') }}
      </v-btn>
    </div>
  </article>
</template>

<script>
import BathroomDwellTime from './BathroomDwellTime.vue'
import { RULESET, RULESET_CONDITION, DEFAULT_DWELL_DURATION } from '../../../views/Notifications/ruleset-enums'
import { mapActions, mapGetters } from 'vuex'
import { updateRule } from '@/api/ruleset'

export default {
  components: { BathroomDwellTime },
  name: 'EditRuleCommunitySettings',
  props: ['rule', 'setLoading', 'loading', 'confirmResettingDwellTime'],
  data () {
    return {
      enabledValue: this.getEnabledValue(this.rule),
      daytimeValue: this.getPropertyValue(this.rule, RULESET_CONDITION.IS_DAYTIME),
      nightTimeValue: this.getPropertyValue(this.rule, RULESET_CONDITION.IS_NIGHT_TIME),
      dwellTime: this.getDwellTime(this.rule)
    }
  },
  computed: {
    ...mapGetters({
      getCurrentCustomerNew: 'login/getCurrentCustomerNew',
      userNew: 'login/getUserNew'
    }),
    inBathroomForXTime () {
      return this.rule.label === RULESET.DWELL_IN_BATHROOM
    }
  },
  methods: {
    ...mapActions({
      getAndSetCommunityRulesets: 'notifications/getAndSetCommunityRulesets'
    }),
    getPropertyValue (ruleset, conditionLabel) {
      return !!ruleset.conditions.find(condition => condition.label === conditionLabel)
    },
    getDwellTime (ruleset) {
      return ruleset.conditions.find(condition => condition.label === RULESET_CONDITION.IS_DWELLING_IN_BATHROOM)?.dwellDuration || DEFAULT_DWELL_DURATION
    },
    getEnabledValue (ruleset) {
      return ruleset.enabled
    },
    async updateEnabled (payload) {
      this.setLoading(true)
      const customerId = this.getCurrentCustomerNew[0] || this.userNew.clientId
      try {
        const response = await updateRule(this.rule.id, customerId, payload)
        if (response) {
          this.setLoading(false)
          await this.getAndSetCommunityRulesets(customerId)
        }
      } catch (error) {
        this.enabledValue = !payload.enabled
        this.setLoading(false)
      }
    },
    async update (payload) {
      const p = { conditions: { ...payload } }
      this.setLoading(true)
      const customerId = this.getCurrentCustomerNew[0] || this.userNew.clientId
      try {
        const response = await updateRule(this.rule.id, customerId, p)
        if (response) {
          this.setLoading(false)
          await this.getAndSetCommunityRulesets(customerId)
        }
      } catch (error) {
        if (payload.hasOwnProperty('daytime')) {
          this.daytimeValue = this.rule.communityDaytime
        } else if (payload.hasOwnProperty('nighttime')) {
          this.nightTimeValue = this.rule.communityNightTime
        }
        this.setLoading(false)
      }
    }
  }
}
</script>

<style scoped>
.edit-rule-community-settings {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.edit-rule-community-settings__subtitle {
  color: rgba(0, 0, 0, 0.6);
}
.edit-rule-community-settings__toggle-buttons {
  line-height: 24px;
  font-weight: bold;
  padding: 10px 0;
  display: flex;
  justify-content: flex-start;
}
.edit-rule-community-settings__toggle-buttons__toggle-button {
  padding-right: 40px;
}
.edit-rule-community-settings__dwell-time {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.edit-rule-community-settings__dwell-time__reset-button {
  font-weight: bold;
}
.enable-button {
  margin-bottom: 20px;
  line-height: 24px;
  font-weight: bold;
}
.edit-rule-community-settings__notification {
  margin-bottom: 15px;
}
</style>
