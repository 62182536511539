import { API_ROOT } from '@/config'
import store from '@/store'

export const getIds = () => {
  const allIds = store?.modules?.login?.state?.myIds || []
  let ids = {}
  for (const authority in allIds) {
    ids[authority] = allIds[authority]?.[0]
  }
  return ids
}

export const buildBaseUrl = () => {
  let { FULFILLER, CUSTOMER } = getIds()
  FULFILLER = !FULFILLER ? 'DEFAULT' : FULFILLER
  CUSTOMER = !CUSTOMER ? 'DEFAULT' : CUSTOMER
  /*  if (!FULFILLER && !CUSTOMER) throw new Error('Insufficient ids to start') */
  return `${API_ROOT}/fulfillers/${FULFILLER}/customers/${CUSTOMER}`
}
export const getMyIds = () => {
  return store?.modules?.login?.state?.myIds || []
}
