<template>
<span>
    <div @click="dialog = true" style="z-index: 1500">
      <Icon  v-if="status === 'fallDetected'" icon-name="bootstrap-reboot" size="20px" color="green" :is-bootstrap="true" />
    </div>
    <div class="text-center">
      <v-dialog
        v-model="dialog"
        width="500"
      >
        <v-card>
          <v-card-title class="headline grey lighten-2">
             {{ $t('resolutions.choose_resolution') }}
          </v-card-title>
          <v-container
            class="px-4"
            fluid
          >
            <v-radio-group v-model="selectedReso">
              <v-radio
                v-for="item in resoCodes"
                :key="item.id"
                :label="`${item.name}`"
                :value="item.id"
              ></v-radio>
            </v-radio-group>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog = false, selectedReso = ''"
            >
              {{ $t('buttons.cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              text
              :disabled="selectedReso.length === 0"
              @click="resolveEvent(), dialog = false"
            >
              {{ $t('buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </span>
</template>

<script>
import Icon from '@/components/elements/Icon'

export default {
  name: 'ResolutionButton',
  components: {
    Icon
  },
  props: {
    status: String,
    resolveEvent: Function
  },
  data () {
    return {
      linkToParent: '',
      dialog: false,
      selectedReso: '',
      resoCodes: [
        { id: 'CONFIRMED_WITH_INJURY', name: 'Confirmed w/ Injury' },
        { id: 'CONFIRMED_WITHOUT_INJURY', name: 'Confirmed w/o Injury' },
        { id: 'REJECTED', name: 'Rejected' },
        { id: 'SIMULATED', name: 'Simulated' },
        { id: 'UNCONFIRMED', name: 'Unconfirmed' }
      ]
    }
  }
}
</script>
