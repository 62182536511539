import Vue from 'vue'
import * as helpers from '../helpers'
import AuthMsgProcessor from '../../components/notifications/auth_msgs'
import ServerMsgProcessor from '../../components/notifications/server_msgs'
import CrudMsgProcessor from '../../components/notifications/crud_msgs'
import {
  getNewRefreshToken,
  clearStorage,
  getUserNew
} from '../../services/authService'

import {
  API_ROOT,
  AUTHENTICATION_API_ROOT,
  KEYCLOAK_API_ROOT,
  FCM_API_ROOT,
  CUSTOMER_MENAGEMENT_API_ROOT
} from '@/config'
import { getMyIds } from '../helpers'

export const login = async creds => {
  const data = creds
  try {
    const response = await Vue.axios.post(API_ROOT + '/public/tokens', data)
    if (response) {
      return response
    }
  } catch (error) {
    handleErr(error)
    return error
  }
}

export const newLogin = async creds => {
  try {
    const response = await Vue.axios.post(
      `${AUTHENTICATION_API_ROOT}/api/users/login`,
      { email: creds.username, password: creds.password }
    )
    return response.data
  } catch (error) {
    AuthMsgProcessor('err', 'invalidClient')
    return error
  }
}

export const refreshNewToken = async () => {
  const token = await getNewRefreshToken()
  if (token) {
    const refNewToken = Object.freeze({
      grant_type: 'refresh_token',
      client_id: 'frontend',
      refresh_token: token
    })
    const data = Object.keys(refNewToken)
      .map(key => `${key}=${encodeURIComponent(refNewToken[key])}`)
      .join('&')
    try {
      const response = await Vue.axios.post(
        `${KEYCLOAK_API_ROOT}/auth/realms/dele/protocol/openid-connect/token`,
        data
      )
      return response.data
    } catch (error) {
      clearStorage()
      handleErr(error)
      return error
    }
  }
}

export const register = async creds => {
  const data = creds
  try {
    const response = await Vue.axios.post(API_ROOT + '/public/users', data)
    AuthMsgProcessor('succ', response)
    if (response?.data?.jwt) return response.data
  } catch (error) {
    handleErr(error)
    return error
  }
}

export const renewToken = async token => {
  const data = {
    jwt: token
  }
  try {
    const response = await Vue.axios.post(
      API_ROOT + '/public/tokens/renewals',
      data
    )
    if (response?.data?.jwt) return response.data
    return null
  } catch (error) {
    throw error
  }
}

export const logout = async () => {
  try {
    const response = await Vue.axios.delete(API_ROOT + '/tokens')
    AuthMsgProcessor('succ', 'loggedOut')
    if (response) return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getMe = async () => {
  try {
    const response = await Vue.axios.get(API_ROOT + '/users/me')
    if (response) return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getAvailableCustomers = async id => {
  const myIds = getMyIds()
  if (myIds.FULFILLER?.[0] || id) {
    const customerList = await Vue.axios.get(API_ROOT + '/customers', {
      headers: {
        'X-Delehealth-FulfillerId': id || myIds?.FULFILLER?.[0]
      }
    })
    if (customerList) return customerList.data
  }
}

export const getClients = async tenantId => {
  try {
    let customerListNew
    if (tenantId) {
      customerListNew = await Vue.axios.get(
        CUSTOMER_MENAGEMENT_API_ROOT + '/api/customers',
        {
          headers: {
            clientid: tenantId
          }
        }
      )
    } else {
      customerListNew = await Vue.axios.get(
        CUSTOMER_MENAGEMENT_API_ROOT + '/api/customers'
      )
    }
    if (customerListNew) return customerListNew.data
  } catch {
    return []
  }
}

export const getTenants = async () => {
  try {
    const tenantsListNew = await Vue.axios.get(
      CUSTOMER_MENAGEMENT_API_ROOT + '/api/tenants'
    )
    if (tenantsListNew) return tenantsListNew.data
  } catch {
    return []
  }
}

export const getAuthority = async (id, type) => {
  const authority = await Vue.axios.get(API_ROOT + `/${type}/${id}`)
  return authority
}

export const getAvailableFulfillers = async () => {
  try {
    const fulfillerList = await Vue.axios.get(API_ROOT + '/fulfillers')
    if (fulfillerList) return fulfillerList.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getIntegrations = async () => {
  const params = { type: 'AMPLIFY' }
  try {
    const response = await Vue.axios.get(
      `${helpers.buildBaseUrl()}/integration-configurations`,
      { params }
    )
    if (response) return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const createNewUser = async data => {
  try {
    const response = await Vue.axios.post(API_ROOT + '/users', data)
    if (response) {
      CrudMsgProcessor('createUser', data?.email)
      return response.data
    } else {
      ServerMsgProcessor('editedRoleErr', data?.email)
    }
  } catch (error) {
    handleErr('err', error)
    throw error
  }
}

export const createNewCustomer = async data => {
  try {
    const myIds = getMyIds()
    if (myIds?.FULFILLER) {
      data.customer.fulfillerId = myIds.FULFILLER[0]
    }
    const response = await Vue.axios.post(API_ROOT + '/customers', data)
    if (response) {
      CrudMsgProcessor('createUser', data?.customer?.name)
      return response.data
    } else {
      ServerMsgProcessor('editedRoleErr', data?.customer?.name)
    }
  } catch (error) {
    handleErr('err', error)
    throw error
  }
}

export const createNewFulfiller = async data => {
  try {
    const response = await Vue.axios.post(API_ROOT + '/fulfillers', data)
    if (response) {
      CrudMsgProcessor('createUser', data?.fulfiller?.name)
      return response.data
    } else {
      ServerMsgProcessor('editedRoleErr', data?.fulfiller?.name)
    }
  } catch (error) {
    handleErr('err', error)
    throw error
  }
}

export const deleteAccount = async (id, type) => {
  const myIds = getMyIds()
  let config = { headers: {} }
  if (myIds?.FULFILLER?.[0]) {
    config.headers['X-Delehealth-FulfillerId'] = myIds?.FULFILLER?.[0]
  }
  if (myIds?.CUSTOMER?.[0]) {
    config.headers['X-Delehealth-CustomerId'] = myIds?.CUSTOMER?.[0]
  }
  try {
    const response = await Vue.axios.delete(API_ROOT + `/${type}/${id}`, {
      ...config
    })
    if (response) return response.data
  } catch (error) {
    console.log(error)
    return error
  }
}

export const getContextsUsers = async () => {
  try {
    const response = await Vue.axios.get(API_ROOT + '/users')
    if (response) return response.data
  } catch (error) {
    console.log(error)
    return error
  }
}

export const getAuthDomainNames = async () => {
  try {
    const response = await Vue.axios.get(
      API_ROOT + '/users/me/authority/domain-names'
    )
    if (response) return response.data
  } catch (error) {
    console.log(error)
    return error
  }
}

export const putContextsUser = async (user, oldPassword, newPassword) => {
  const data = {
    user: user,
    oldPassword: oldPassword,
    newPassword: newPassword
  }
  try {
    const response = await Vue.axios.put(API_ROOT + '/users/me/password', data)
    if (response) {
      CrudMsgProcessor('editCurrUser', data.user.email)
      return response.data
    } else {
      CrudMsgProcessor('editCurrUserErr', data.user.email)
    }
  } catch (error) {
    console.log(error)
    if (error) CrudMsgProcessor('editCurrUserErr', data.user.email)
    return error
  }
}

export const adjustUserPermission = async (user, promote) => {
  let data = {
    authorityType: user.roles[user.roles.length - 1].split('_')[0],
    email: user.email
  }
  if (user.roles[user.roles.length - 1].split('_')[0] === 'FULFILLER') {
    data.fulfillerId = user.authorities.FULFILLER[0]
  }
  // Use Customer id if customer role, otherwise pass the DEFAULT (original value) for fulfiller
  data.customerId = user.roles[user.roles.length - 1].split('_')[0] === 'FULFILLER' ? 'DEFAULT' : user.authorities.CUSTOMER[0]
  let msgText = promote ? 'Promoted ' + data?.email : 'Demoted ' + data?.email
  let shouldPromote = promote ? 'promotions' : 'demotions'

  await Vue.axios
    .post(API_ROOT + '/users/' + shouldPromote, data)
    .then(response => {
      if (response) CrudMsgProcessor('editedRole', msgText)
      if (response?.code === 'E_AUTHORIZATION') {
        CrudMsgProcessor('editedRoleErr', 'Authorization Error')
      }
      if (response) return response.data
    })
    .catch(error => {
      CrudMsgProcessor('editedRoleErr', msgText)
      return error
    })
}
export const trashUser = async user => {
  console.log('trashUser', user.uid)
  try {
    const response = await Vue.axios.delete(API_ROOT + '/users/' + user.uid)
    if (response) CrudMsgProcessor('deletedUser', user.email)
    if (response) return response.data
  } catch (error) {
    console.log(error)
    CrudMsgProcessor('deletedUserErr', user.email)
    return error
  }
}

export const fcmSubscribe = async (fcmToken, topic, action) => {
  const user = await getUserNew()
  if (user && fcmToken) {
    await Vue.axios
      .post(`${FCM_API_ROOT}/api/fcm/${action}`, {
        token: fcmToken,
        topic: `${user.clientId}_${topic}`
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        CrudMsgProcessor('fcmError', error.response.data.message)
        return error
      })
  }
  return true
}

function handleErr (err) {
  err?.response?.status
    ? AuthMsgProcessor('err', err)
    : ServerMsgProcessor('', err)
}
