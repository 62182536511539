import Vue from 'vue'
import * as helpers from '../helpers'
import CrudMsgProcessor from '@/components/notifications/crud_msgs'
import ServerMsgProcessor from '@/components/notifications/server_msgs'

export const getActiveDevicePauses = async () => {
  const url = `${helpers.buildBaseUrl()}/device-pauses`
  try {
    const response = await Vue.axios.get(url)
    return response
  } catch (err) {
    handleErr(err)
    throw err
  }
}

export const upsertDevicePause = async data => {
  let response
  const url = `${helpers.buildBaseUrl()}/device-pauses`
  try {
    response = await Vue.axios.post(url, data)
    CrudMsgProcessor('device_pause_upsert', data.vendorDeviceId)
    return response
  } catch (err) {
    handleErr(err)
    throw err
  }
}

function handleErr (err) {
  console.log(err, 'handling ERRRR')
  err?.response?.status ? CrudMsgProcessor('err', err) : ServerMsgProcessor('', err)
}
