export default {
  state: () => ({
    loggedIn: false,
    newToken: '',
    myIds: {
      fulfullerId: 'DEFAULT',
      customerId: 'DEFAULT',
      myAuthority: ''
    },
    myCustomers: [],
    myFulfillers: [],
    myInfo: null,
    myIntegrations: [],
    eventRuleset: [],
    myInfoNew: null,
    activeTenant: null
  })
}
