export default {
  state: () => ({
    containers: [],
    containerTreeAsObject: {},
    selectedContainer: '',
    currentRoot: {},
    showSlideDrawer: false,
    roots: [],
    editingContainer: {
      editData: {}, toggle: false
    },
    importStarted: false
  })
}
