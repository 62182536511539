<template>
  <section
    class="slide-bar"
    :class="{ 'slide-in': communityRuleForEdit }"
    v-if="communityRuleForEdit"
  >
    <header class="slide-slot-top slide-drawer-edit-rule__header">
      {{ communityRuleForEdit.transformedLabel }}
      <AlertTypeLabel :type="communityRuleForEdit.type" class="slide-drawer-edit-rule__label" />
    </header>
    <main class="slide-slot-mid slide-drawer-edit-rule__content">
      <div v-if="!showConfirmationMessageAndHideRuleSettings" class="slide-slot-mid slide-drawer-edit-rule__content-container">
        <EditRuleCommunitySettings :rule="communityRuleForEdit" :confirmResettingDwellTime="showResetMessage" :setLoading='setLoading' :loading="loading"/>
        <EditRuleApartmentSetting :communityRule="communityRuleForEdit" :setLoading='setLoading' :loading="loading"/>
      </div>
      <div v-else>
        <ConfirmDwellTimeReset :cancelResettingAction="cancelResettingAction" :confirmResettingAction="confirmResettingDwellTime"/>
      </div>
    </main>
    <footer class="slot-bottom slide-drawer-edit-rule__footer">
      <v-btn v-if="!showConfirmationMessageAndHideRuleSettings" text class="slide-drawer-edit-rule__cloce-button_bold" @click.stop="closeSlideDrawer">
        {{ $t('buttons.close') }}
      </v-btn>
      <span v-if="!showConfirmationMessageAndHideRuleSettings" class="slide-drawer-edit-rule__footer__span">
        <div v-if="loading" class="slide-drawer-edit-rule__footer__span">
          <v-progress-circular
            class="slide-drawer-edit-rule__footer__span__icon"
            :size="15"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <i class="fas fa-check slide-drawer-edit-rule__footer__span__icon"></i>
        </div>
        {{ $t('buttons.autoSaved') }}
      </span>
    </footer>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AlertTypeLabel from '../../AlertConfiguration/AlertTypeLabel.vue'
import EditRuleCommunitySettings from './EditRuleCommunitySettings.vue'
import EditRuleApartmentSetting from './EditRuleApartmentSetting.vue'
import ConfirmDwellTimeReset from './ConfirmDwellTimeReset.vue'
import { updateRule } from '@/api/ruleset'
import { DEFAULT_DWELL_DURATION } from '../../../views/Notifications/ruleset-enums'

export default {
  name: 'SlideDrawerEditRule',
  components: {
    AlertTypeLabel,
    EditRuleCommunitySettings,
    EditRuleApartmentSetting,
    ConfirmDwellTimeReset
  },
  data () {
    return {
      loading: false,
      showConfirmationMessageAndHideRuleSettings: false
    }
  },
  computed: {
    ...mapGetters({
      communityRuleForEdit: 'notifications/communityRuleForEdit',
      getCurrentCustomerNew: 'login/getCurrentCustomerNew',
      userNew: 'login/getUserNew'
    })
  },
  methods: {
    ...mapActions({
      editCommunityRule: 'notifications/editCommunityRule',
      clearApartments: 'notifications/clearApartments',
      getAndSetCommunityRulesets: 'notifications/getAndSetCommunityRulesets'
    }),
    setLoading (value) {
      this.loading = value
    },
    showResetMessage () {
      this.showConfirmationMessageAndHideRuleSettings = true
    },
    async confirmResettingDwellTime () {
      try {
        const customerId = this.getCurrentCustomerNew[0] || this.userNew.clientId
        const response = await updateRule(this.communityRuleForEdit.id, customerId, { conditions: { dwellDuration: DEFAULT_DWELL_DURATION } })
        await this.editCommunityRule(response)
        await this.getAndSetCommunityRulesets(customerId)
        this.showConfirmationMessageAndHideRuleSettings = false
      } catch (error) {}
    },
    cancelResettingAction () {
      this.showConfirmationMessageAndHideRuleSettings = false
    },
    closeSlideDrawer () {
      this.editCommunityRule(null)
      this.clearApartments()
    }
  }
}
</script>
<style scoped>
.slide-drawer-edit-rule__footer,
.slide-drawer-edit-rule__header {
  padding: 23px 20px;
  min-height: 70px;
  font-size: 18px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.slide-drawer-edit-rule__content {
  max-height: calc(100vh - 2 * 70px);
  font-size: 12px;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
}
.slide-drawer-edit-rule__label {
  min-width: auto;
  margin: 0;
  font-size: 10px;
  font-weight: normal;
}
.slide-drawer-edit-rule__cloce-button_bold {
  font-weight: bold;
}
.slide-drawer-edit-rule__footer__span {
  font-size: 13px;
  letter-spacing: 0.46px;
  color: #000;
  display: flex;
  align-items: center;
}
.slide-drawer-edit-rule__footer__span__icon {
  color:#42BB5D;
  margin-right: 9px;
}
.slide-drawer-edit-rule__content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}
</style>
