import config from './config'
import { getMyIds } from '@/api/helpers'
import localforage from 'localforage'

export default {
  RESET_STATE (state) {
    // Setting each key separately is needed for nested state
    for (const key of Object.keys(config.state())) {
      state[key] = config.state()[key]
    }
  },
  SET_DEVICES (state, payload) {
    state.devices = payload || []
  },
  SET_REGISTERED_DEVICES (state, payload) {
    state.registeredDevices = payload || []
  },
  SET_DEVICE_INFO (state, payload) {
    state.deviceInfo = payload || {}
  },
  NEW_DEVICE_LISTENER (state, payload) {
    if (payload) {
      const index = state.deviceListeners.findIndex(
        (listener) => listener === payload
      )
      if (index < 0) {
        state.deviceListeners.push(payload)
      }
    }
  },
  SET_LATEST_DEVICE_MESSAGE (state, payload) {
    state.latestDeviceMessage = payload
  },
  NEW_DEVICE_EVENT (state, payload) {
    const myIds = getMyIds()
    const did = payload?.meta?.event?.did
    const deviceStatus =
      state.deviceInfo[payload?.data.device?.id]?.status || {}

    switch (did) {
      case 'beat-heart':
        state.warningMessages = state.warningMessages.filter(
          (message) => message.data.device.id !== payload.data.device.id
        )
        state.alarmMessages = state.alarmMessages.filter((message) => {
          const lastHeard = new Date(
            Date.parse(message?.data?.device?.status?.FALL?.instant)
          ).getTime()
          return isNaN(lastHeard)
            ? false
            : Date.now() - lastHeard < 1000 * 60 * 10 // (10 minutes in millis)
        })

        if (deviceStatus) {
          deviceStatus.isOffline = false
        }
        break
      case 'go-offline':
        if (
          !state.warningMessages.some(
            (message) => message.data.device.id === payload.data.device.id
          )
        ) {
          state.warningMessages = state.warningMessages.concat(payload)
        }
        if (deviceStatus) {
          deviceStatus.isOffline = true
        }

        break
      case 'report-fall':
        state.alarmMessages = state.alarmMessages.concat(payload)
        deviceStatus.isFall = true
        break
      case 'detect-presence':
        if (deviceStatus) {
          deviceStatus.isAbsence = false
        }
        break
      case 'detect-absence':
        if (deviceStatus) {
          deviceStatus.isAbsence = true
        }
        break
      case 'resolve-event':
        state.alarmMessages = state.alarmMessages.filter(
          (message) => message.data.device.id !== payload.data.device.id
        )
        break
      case 'import-amplify-org':
        localforage.setItem(myIds?.CUSTOMER?.[0] + '/Amplify_Import', false)
        state.latestOtherEventMessage = payload
        break
      default:
        state.latestDeviceMessage = payload
        break
    }
  },
  CLEAR_FALL_EVENT (state, payload) {
    if (payload?.data) {
      const deviceStatus = state.deviceInfo[payload?.data?.device?.id]?.status
      if (deviceStatus) deviceStatus.isFall = false
    } else if (payload?.id) {
      const deviceStatus = state.deviceInfo[payload.id]?.status
      if (deviceStatus) deviceStatus.isFall = false
    }
  },
  SET_SIMULATED_EVENT (state, payload) {
    state.simulatedEvents = [...state.simulatedEvents, payload]
  }
}
