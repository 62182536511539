<template>
  <div class="bathrom-dwell-time">
    <v-text-field
      class="bathrom-dwell-time__input"
      v-model="dwellMinutes"
      :disabled="disabled || loading"
      :hint="dwellMinutes ? 'minutes' : ''"
      min=15
      max=240
      :error="doesNotPassDwellRangeCheck"
      :error-messages="doesNotPassDwellRangeCheck ? 'Minimum 15min, maximum 240min' : ''"
      persistent-hint
      placeholder="minutes"
      type="number"
      @blur="update({ dwellDuration: parseInt($event.target.value) })"
    >
    </v-text-field>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { updateRule } from '@/api/ruleset'
export default {
  name: 'BathroomDwellTime',
  props: ['rule', 'dwellTime', 'community', 'disabled', 'loading', 'setLoading'],
  data () {
    return {
      dwellMinutes: this.dwellTime,
      oldDwellMinutes: this.dwellTime
    }
  },
  computed: {
    ...mapGetters({
      getCurrentCustomerNew: 'login/getCurrentCustomerNew',
      userNew: 'login/getUserNew'
    }),
    doesNotPassDwellRangeCheck () {
      return this.dwellMinutes < 15 || this.dwellMinutes > 240
    }
  },
  methods: {
    ...mapActions({
      getAndSetCommunityRulesets: 'notifications/getAndSetCommunityRulesets',
      updateSingleApartment: 'notifications/updateSingleApartment',
      editCommunityRule: 'notifications/editCommunityRule'
    }),
    async update (payload) {
      const p = { conditions: { ...payload } }
      if (this.dwellMinutes >= 15 && this.dwellMinutes <= 240 && !!this.rule) {
        try {
          const customerId = this.getCurrentCustomerNew[0] || this.userNew.clientId
          this.setLoading(true)
          const response = await updateRule(this.rule.id, customerId, p)
          if (response) {
            await this.getAndSetCommunityRulesets(customerId)
            if (this.community) {
              this.editCommunityRule(response)
            } else {
              this.updateSingleApartment(response)
            }
            this.setLoading(false)
          }
        } catch (error) {
          this.dwellMinutes = this.dwellTime
          this.setLoading(false)
        }
      }
    }
  }
}
</script>

<style scoped>
.bathrom-dwell-time {
  width: 80%;
  display: flex;
}
.bathrom-dwell-time__input {
  padding: 0 0 0 0;
  max-width: 115px;
  display: inline-block;
}
</style>
