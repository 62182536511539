/**
 * @typedef {string} RulesetType
 **/

/**
 * @enum {RulesetType}
 */
export const RULESET = Object.freeze({
  DWELL_IN_BATHROOM: 'dwellInBathroom',
  FALL_REPORTED: 'fallReported',
  FALL_DETECTED: 'fallDetected',
  IN_BED: 'inBedDuringDaytime',
  OUT_OF_BED: 'outOfBed',
  IN_BATHROOM: 'inBathroomDuringNightTime',
  LEFT_THE_APARTMENT: 'awayFromApartment'
})

export const RULESET_CONDITION = Object.freeze({
  IS_DAYTIME: 'isDayTime',
  IS_NIGHT_TIME: 'isNightTime',
  IS_FALL_DETECTED: 'isFallDetected',
  IS_FALL_REPORTED: 'isFallReported',
  IS_IN_BED: 'isInBed',
  IS_IN_ROOM_AFTER_ABSENCE: 'isInRoomAfterAbsence',
  IS_NOT_IN_BED: 'isNotInBed',
  IS_NOT_IN_ROOM: 'isNotInRoom',
  IS_ON_TOILET: 'isOnToilet',
  IS_DWELLING_IN_BATHROOM: 'isDwellingInBathroom'
})

export const RULESET_ACTIONS = Object.freeze({
  SAVE_NOTIFICATION_TO_DB: 'saveNotificationToDB',
  SEND_SMART_NOTIFICATION: 'sendSmartNotification'
})

export const DEFAULT_DWELL_DURATION = 60

// export default { RULESET, RULESET_CONDITION, RULESET_ACTIONS, DEFAULT_DWELL_DURATION }
