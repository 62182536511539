<template>
  <span :style="iconSize">
    <i
      v-if="!isBootstrap"
       :class="['fas ', 'fa-' + iconName, 'fa-3x']"
       :style="iconColor"

    ></i>
    <div v-if="isBootstrap" :style="iconSize">
      <b-icon
        :icon="iconName"
        :style="iconColor"
      >
      </b-icon>
    </div>
  </span>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    iconName: String,
    color: {
      type: String,
      default: 'grey',
      required: false
    },
    size: {
      type: String,
      default: '16px',
      required: false
    },
    isBootstrap: {
      type: Boolean,
      default: false,
      require: false
    }
  },
  computed: {
    iconColor: function () {
      const currColor = this.color
      return {
        color: currColor
      }
    },
    iconSize: function () {
      const currSize = this.size
      return {
        fontSize: currSize
      }
    }
  }
}
</script>
