<template>
  <article class="edit-rule-apartment-setting">
    <v-text-field class="edit-rule-apartment-setting__search" :label="$t('notification.searchApartments')" v-model="searchValue"/>
    <div class="edit-rule-apartment-setting__content" v-on:scroll.passive="handleInfiniteScroll" v-bind:style="{ maxHeight: height + 'px'}">
      <ApartmentCard v-for="apartment in foundApartments" :key="apartment.number" :communityRule="communityRule" :apartment="apartment" :setLoading="setLoading" :loading="loading"/>
    </div>
    <div v-if="!foundApartments.length && searchValue">{{ $t('notification.apartmentNotFound') }}</div>
  </article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ApartmentCard from './ApartmentCard.vue'
import { getApartmentRulesets } from '@/api/ruleset'
import CrudMsgProcessor from '@/components/notifications/crud_msgs'
import ServerMsgProcessor from '@/components/notifications/server_msgs'

import _ from 'lodash'

const PAGINATION_OFFSET = 100
const DEBOUNCE_TIME = 1000

export default {
  name: 'EditRuleApartmentSetting',
  components: { ApartmentCard },
  props: ['communityRule', 'setLoading', 'loading'],
  data () {
    return {
      communityId: undefined,
      data: [],
      searchValue: '',
      foundApartments: [],
      page: 1,
      size: 10,
      itemCount: undefined,
      totalElements: undefined,
      totalPages: undefined,
      isLoading: false,
      height: undefined
    }
  },
  computed: {
    ...mapGetters({
      getCurrentCustomerNew: 'login/getCurrentCustomerNew',
      userNew: 'login/getUserNew',
      apartments: 'notifications/apartments'
    })
  },
  async mounted () {
    this.communityId = this.getCurrentCustomerNew[0] || this.userNew.clientId
    await this.fetchApartments(this.communityId, this.page, this.size, this.communityRule.label, this.searchValue)
    this.calculateHeight()
    window.addEventListener('resize', this.calculateHeight)
  },
  unmounted () {
    window.removeEventListener('resize')
  },
  methods: {
    ...mapActions({
      setApartments: 'notifications/setApartments',
      updateSingleApartment: 'notifications/updateSingleApartment'
    }),
    async fetchApartments (communityId, page, size, label, searchValue) {
      try {
        this.setLoading(true)
        this.data = await getApartmentRulesets(communityId, page, size, label, searchValue)
        this.setApartments([...this.foundApartments, ...this.data.items])
        this.setPaginationData(this.data.meta)
        this.setLoading(false)
      } catch (error) {
        this.setLoading(false)
        handleErr(error)
      }
    },
    async handleInfiniteScroll (e) {
      const scrollY = e.target.scrollHeight - e.target.scrollTop
      const offsetHeight = e.target.offsetHeight
      const difference = scrollY - offsetHeight
      try {
        if (difference < PAGINATION_OFFSET && this.page < this.totalPages && this.loading === false) {
          await this.fetchApartments(this.communityId, this.page + 1, this.size, this.communityRule.label, this.searchValue)
        }
      } catch (error) {
        this.setLoading(false)
        handleErr(error)
      }
    },
    setPaginationData (metaData) {
      if (metaData) {
        this.page = metaData.currentPage
        this.itemCount = metaData.itemCount
        this.totalElements = metaData.totalItems
        this.totalPages = metaData.totalPages
      }
    },
    searchApartments: _.debounce(async function () {
      try {
        this.setLoading(true)
        this.data = await getApartmentRulesets(this.communityId, 1, this.size, this.communityRule.label, this.searchValue)
        this.setApartments([...this.data.items])
        this.setPaginationData(this.data.meta)
        this.setLoading(false)
      } catch (error) {
        handleErr(error)
        this.setLoading(false)
      }
    }, DEBOUNCE_TIME),
    calculateHeight () {
      if (this.height !== document.documentElement.clientHeight) {
        const containerHeight = document.querySelector('.slide-drawer-edit-rule__content-container')?.getBoundingClientRect().height
        const communitySettingsHeight = document.querySelector('.edit-rule-community-settings')?.getBoundingClientRect().height
        const searchHeight = document.querySelector('.edit-rule-apartment-setting__search')?.getBoundingClientRect().height
        this.height = containerHeight - communitySettingsHeight - searchHeight
      }
    }
  },
  watch: {
    searchValue () {
      this.searchApartments()
    },
    apartments (newValue) {
      this.foundApartments = newValue
    }
  }
}

function handleErr (err) {
  err?.response?.status ? CrudMsgProcessor('err', err) : ServerMsgProcessor('', err)
}

</script>

<style scoped>
.edit-rule-apartment-setting {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
.edit-rule-apartment-setting__search {
  margin-top: 0px;
  padding-top: 16px;
}
.edit-rule-apartment-setting__content {
  overflow: auto;
}
.edit-rule-apartment-setting__loader {
  z-index: 10;
  position: absolute;
  width: 100%;
  top: 50%;
  background-color: transparent;
  pointer-events: none;
}
</style>
