import { cloneDeep } from 'lodash'

export default {
  getAlerts (state) {
    return state.alerts.slice().sort((a, b) => a.priority - b.priority)
  },
  getContainerInfo (state, getters) {
    let allContainers = cloneDeep(
      getters['containers/getContainerTreeAsObject']
    )
    let allDevices = cloneDeep(getters['devices/getDeviceInfo'])
    for (const [containerId, container] of Object.entries(allContainers)) {
      // Set up data structure
      container.containerStatus = 'noDevices'
      container.deviceInfo = {}
      container.deviceInfo.devices = []
      container.deviceInfo.deviceContainers = []
      container.deviceInfo.nestedDevices = []
      let containerStatus = 'ok'
      let numAbsences = 0
      const allDescendantDevices = [
        ...container.deviceIds,
        ...container.nestedDeviceIds
      ]
      for (const [deviceId, device] of Object.entries(allDevices)) {
        // Grab info for direct child devices
        if (container && container.deviceIds.includes(deviceId)) {
          // TODO: after determining which info is needed elsewhere, push just that info (not the whole device)
          container.deviceInfo.devices.push(device)
        }
        // Grab info for nested devices
        if (container && container.nestedDeviceIds.includes(deviceId)) {
          // If it's a device container that's a direct child of this container, save in a separate array
          if (
            !!device.container?.renderingMetadata?.deviceId &&
            device.container.parentId === containerId
          ) {
            container.deviceInfo.deviceContainers.push(device)
          } else {
            container.deviceInfo.nestedDevices.push(device)
          }
        }
        // Check for possible update based on this device's status and current container status
        if (container && allDescendantDevices.includes(deviceId)) {
          // TODO: The next 3 "if" blocks could be extracted into a constant file that could eventually become db-fed
          // TODO: Above via containerStatus = StatusPriority[device.status];
          if (device.status.isFall) {
            containerStatus = 'fallDetected' // if any fall, that's automatically the container status
          }
          if (device.status.isOffline && containerStatus !== 'fallDetected') {
            containerStatus = 'noHeartbeat' // set offline status if none with higher priority
          }
          if (device.status.isAbsence) {
            numAbsences++
            // set noPresence status if every descendant device registers absence and no higher priority status
            if (numAbsences === allDescendantDevices.length) {
              if (
                containerStatus !== 'fallDetected' &&
                containerStatus !== 'noHeartbeat'
              ) {
                containerStatus = 'noPresence'
              }
            }
          }
          container.containerStatus = containerStatus
        }
      }
    }
    return allContainers
  },
  getKnownAdminRoles (state) {
    return state.knownAdminRoles
  }
}
