import api from '@/api'
import * as authService from '@/services/authService'
import AuthMsgProcessor from '@/components/notifications/auth_msgs'
import CrudMsgProcessor from '@/components/notifications/crud_msgs'
import ServerMsgProcessor from '@/components/notifications/server_msgs'
import Vue from 'vue'
import { getAuthority, fcmSubscribe } from '@/api/users'
import * as notificationService from '../../../services/notificationService'
import messaging from '../../../plugins/firebase/firebase'
import jwtDecode from 'jwt-decode'

export const doLogin = async ({ commit, state, dispatch }, data) => {
  try {
    const res = await api.users.login(data)
    if (res.data.hasOwnProperty('jwt') === true) {
      const newRes = await api.users.newLogin(data)
      if (newRes.access_token && newRes.refresh_token) {
        AuthMsgProcessor('succ', res)
        const user = jwtDecode(newRes.access_token)
        await authService.saveToken(res.data.jwt)
        await authService.saveUser(res.data.user)
        await authService.saveNewToken(newRes.access_token)
        await authService.saveNewRefreshToken(newRes.refresh_token)
        await authService.saveUserNew(user)
        await checkForIntegrations({ commit, state })
        await getAvailableCustomersandFulfillers({ commit, state })
        commit('LOGIN', true)
        commit('SET_MY_INFO', res.data.user)
        commit('SET_MY_INFO_NEW', user)
        dispatch('devices/listenToDevices')
        return true
      }
    }
    commit('LOGOUT', false)
    return false
  } catch (err) {
    return err
  }
}

export const refreshToken = async ({ commit, state }, data) => {
  try {
    const currentToken = await authService.getToken()
    const res = await api.users.renewToken(currentToken)

    if (res?.jwt && res?.user) {
      await authService.saveToken(res.jwt)
      await authService.saveUser(res.user)
      await checkForIntegrations({ commit, state })

      commit('LOGIN', true)
      commit('SET_MY_INFO', res.user)
      return true
    }
    return false
  } catch (err) {
    commit('LOGOUT')
    throw err
  }
}
export const getAvailableCustomersandFulfillers = async ({ commit, state }) => {
  const isSuperadmin = state.myInfo?.roles?.some(role =>
    role.includes('SUPERADMIN')
  )
  const isFulfiller = state.myInfo?.roles?.some(role =>
    role.includes('FULFILLER')
  )
  if (isSuperadmin) {
    try {
      const fulfillerOpts = await api.users.getAvailableFulfillers()
      const tenantsOpts = await api.users.getTenants()
      if (fulfillerOpts && tenantsOpts) {
        const fulfillers = fulfillerOpts.map(fulfiller => {
          if (tenantsOpts.length) {
            const tenant = tenantsOpts.find(tenant => {
              if (tenant.fulfillerIdOld === fulfiller.id) {
                return tenant
              }
            })
            const fulfillerWithAdditionProps = {
              ...fulfiller,
              tenantPropsNew: { ...tenant }
            }
            return fulfillerWithAdditionProps
          } else {
            return fulfiller
          }
        })
        console.log(fulfillers, 'Fulfillers fetching if superadmin')
        commit('SET_MY_FULFILLERS', fulfillers)
      }
    } catch (err) {
      console.log('No Fulfillers')
    }
  }
  if (isFulfiller || isSuperadmin) {
    try {
      let customerOpts
      let clientsOpts
      if (isSuperadmin && state.myFulfillers?.length > 0) {
        customerOpts = await api.users.getAvailableCustomers(
          state.myFulfillers[0].id
        )
        clientsOpts = await api.users.getClients(
          state.myFulfillers[0].tenantPropsNew?.clientId
        )
      } else {
        customerOpts = await api.users.getAvailableCustomers()
        clientsOpts = await api.users.getClients()
      }
      if (customerOpts && clientsOpts) {
        const customers = customerOpts.map(customer => {
          if (clientsOpts.length) {
            const client = clientsOpts.find(client => {
              if (client.customerIdOld === customer.id) {
                return client
              }
            })
            const customerWithAdditionProps = {
              ...customer,
              clientPropsNew: { ...client }
            }
            return customerWithAdditionProps
          } else {
            return customer
          }
        })
        if (!customers.length) {
          commit('SET_ACTIVE_CUSTOMER_NEW', null)
        }
        commit('SET_MY_CUSTOMERS', customers)
        console.log('Customers fetching', customers)
      }
    } catch (err) {
      console.log('Issue getting Customers', err)
    }
  }
  return true
}

export const getAuthorityName = async ({ commit, state }, data) => {
  const { id, type } = data
  try {
    const authority = await getAuthority(id, type)
    commit('SET_MY_AUTHORITY', authority.data)
    return authority.data
  } catch (err) {
    console.log('Issue getting Customers', err)
  }
  return true
}

export const doRegister = async ({ commit, state }, data) => {
  try {
    if (state.loggedIn) await api.users.logout()
    await authService.removeToken()
    const res = await api.users.register(data)
    if (res?.jwt) {
      await getAvailableCustomersandFulfillers({ commit, state })
      await authService.saveToken(res.jwt)
      await authService.saveUser(res.user)
      await checkForIntegrations({ commit, state })
      commit('LOGIN', true)
      commit('SET_MY_INFO', res.user)
      return true
    }
    commit('LOGOUT', false)
    return false
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const doLogout = async ({ commit, dispatch, state }) => {
  try {
    const fcmToken = await notificationService.getFirebaseToken()
    const unsubscribeHard = await fcmSubscribe(fcmToken, 'fall', 'unsubscribe')
    const unsubscribeSmart = await fcmSubscribe(
      fcmToken,
      'smart',
      'unsubscribe'
    )
    if (unsubscribeHard && unsubscribeSmart) {
      if (messaging && fcmToken) {
        messaging.deleteToken().catch((err) => {
          err?.response?.status
            ? CrudMsgProcessor('err', err)
            : ServerMsgProcessor('', err)
          throw err
        })
      }
      await authService.clearStorage()
      dispatch('resetState', null, { root: true })
      commit('LOGOUT', null)
      // await api.users.logout() TODO: Nonstop error using this api endpoint
      AuthMsgProcessor('succ', 'loggedOut')
      Vue.$socketApi.disconnect()
    }
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const fetchMyInfo = async ({ commit, state }, data) => {
  try {
    const res = await api.users.getMe()
    if (res.hasOwnProperty('user') === true) {
      await getAvailableCustomersandFulfillers({ commit, state })
      await checkForIntegrations({ commit, state })
      commit('LOGIN', true)
      commit('SET_MY_INFO', res.user)
      return true
    }
    return false
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const checkForIntegrations = async (
  { commit, state },
  getCustomers = true
) => {
  try {
    const res = await api.users.getIntegrations()
    commit('SET_MY_INTEGRATIONS', res)
    getCustomers &&
      (await getAvailableCustomersandFulfillers({ commit, state }))
  } catch (err) {
    return []
  }
}

export const createCustomer = async ({ commit, dispatch }, data) => {
  try {
    const customerOpts = await api.users.createNewCustomer(data)
    if (customerOpts) {
      commit('ADD_TO_MY_CUSTOMERS', customerOpts)
    }
    return customerOpts
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const createFulfiller = async ({ commit, dispatch }, data) => {
  try {
    const customerOpts = await api.users.createNewFulfiller(data)
    if (customerOpts) {
      commit('ADD_TO_MY_FULFILLERS', customerOpts)
    }
    return customerOpts
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const deleteAccount = async ({ commit, dispatch, state }, data) => {
  const { id, type } = data
  try {
    api.users.deleteAccount(id, type).then(async res => {
      await getAvailableCustomersandFulfillers({ commit, state })
    })
    return true
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const getEventRulesetForCustomer = async (
  { commit, state },
  customerId
) => {
  if (customerId) {
    const data = await api.users.getCommunityRulesets(customerId)
    if (data) {
      commit('SET_EVENT_RULESET', data)
    }
  } else {
    commit('SET_EVENT_RULESET', [])
  }
  return true
}
