import localforage from 'localforage'

export async function saveToken (token) {
  if (window && window.axios) window.axios.defaults.headers.common['Authorization'] = `Bearer ${token.replace(/(^")|("$)/g, '')}`
  return await localforage.setItem('token', token)
}

export async function saveNewToken (token) {
  return await localforage.setItem('newToken', token)
}

export async function saveNewRefreshToken (token) {
  return await localforage.setItem('newRefreshToken', token)
}

export async function getNewToken () {
  return await localforage.getItem('newToken')
}

export async function getNewRefreshToken () {
  return await localforage.getItem('newRefreshToken')
}

export async function getToken () {
  const token = await localforage.getItem('token')
  if (token && window && window.axios) window.axios.defaults.headers.common['Authorization'] = `Bearer ${token.replace(/(^")|("$)/g, '')}`
  return token
}

export async function removeToken (name) {
  return await localforage.removeItem('token')
}

export async function saveUser (user) {
  return await localforage.setItem('user', user)
}

export async function saveUserNew (user) {
  return await localforage.setItem('userNew', user)
}

export async function getUserNew () {
  return await localforage.getItem('userNew')
}

export async function getUser (name) {
  return await localforage.getItem('user')
}

export async function removeUser (name) {
  return await localforage.removeItem('user')
}

export async function saveFirebaseToken (token) {
  return await localforage.setItem('firebaseToken', token)
}

export async function getFirebaseToken () {
  return await localforage.getItem('firebaseToken')
}

export async function removeFirebaseToken () {
  return await localforage.removeItem('firebaseToken')
}

export async function clearStorage () {
  return await localforage.clear()
}
