import * as containers from './containers'
import * as devices from './devices'
import * as users from './users'
import * as pauses from './pauses'
import * as ruleset from './ruleset'

export default {
  containers,
  devices,
  users,
  pauses,
  ruleset
}
