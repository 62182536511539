import { ErrMsg } from '@/components/notifications/_notifications'
import { serverMsg } from '@/components/notifications/_message_text'

export default function ServerMsgProcessor (type, msg = '') {
  if (msg?.response?.status === 404) {
    ErrMsg(serverMsg.Server.NoConnection(msg))
  } else if (msg?.response?.status === 401) {
    ErrMsg(serverMsg.Server.unauthorized(msg))
  } else if (msg?.response?.status === 500) {
    ErrMsg(serverMsg.Server.generalError(msg))
  }
}
