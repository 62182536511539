import config from './config'
import TreeModel from 'tree-model-improved'
const treeModel = new TreeModel({
  modelComparatorFn: (a, b) => a.name?.toLowerCase() > b.name?.toLowerCase()
})

export default {
  RESET_STATE (state) {
    for (const key of Object.keys(config.state())) {
      state[key] = config.state()[key]
    }
  },
  SET_CONTAINERS (state, payload) {
    const model = payload || {}
    const tree = treeModel.parse(model)
    state.containers = tree.model
  },
  SET_ROOTS (state, payload) {
    state.roots = payload
  },
  SET_ROOT_CONTAINER (state, payload) {
    state.currentRoot = payload
  },
  SET_CONTAINERS_AS_OBJECT (state, payload) {
    state.containerTreeAsObject = payload
  },
  SET_SELECTED_CONTAINER (state, payload) {
    state.selectedContainer = payload.container.id
    state.showSlideDrawer = payload.show
  },
  CLEAR_SELECTED_CONTAINER (state) {
    state.selectedContainer = {}
  },
  TOGGLE_SLIDE_DRAWER (state, payload) {
    state.showSlideDrawer = payload
  },
  SET_TOGGLE_EDITING_CONTAINER (state, payload) {
    state.editingContainer = { toggle: payload.toggle, editing: payload.editData }
  },
  SET_IMPORT_STARTED (state, payload) {
    state.importStarted = payload
  }
}
