import { ErrMsg, SuccMsg } from '@/components/notifications/_notifications'
import { authMsg, serverMsg } from '@/components/notifications/_message_text'

export default function AuthMsgProcessor (type, msg) {
  switch (type) {
    case 'err':
      if (msg === 'emailIssue') {
        ErrMsg(authMsg.Signup.emailError)
      } else if (msg === 'invalidClient') {
        ErrMsg(authMsg.Login.error)
      } else if (msg === 'psswdIssue') {
        ErrMsg(authMsg.Common.password)
      } else if ([401, 402].includes(msg?.response?.status)) {
        ErrMsg(authMsg.Login.fail)
      } else if (msg?.response?.status === 500) {
        ErrMsg(authMsg.Signup.alreadyExists())
      } else {
        ErrMsg(serverMsg.Server.generalError())
      }
      // else if (msg?.response?.status === 404) {
      //   ErrMsg(serverMsg.Server.NoConnection(msg))
      // }

      break
    case 'succ':
      if ([200, 204].includes(msg?.status)) {
        SuccMsg(authMsg.Login.success(msg?.data?.user?.email))
      } else if (msg?.status === 201) {
        SuccMsg(authMsg.Signup.success(msg?.data?.user?.email))
      } else if (msg === 'loggedOut') {
        SuccMsg(authMsg.Logout.success)
      }
      break
    default:
      ErrMsg(authMsg.Common.generalErr)
      break
  }
}
