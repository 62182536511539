<template>
  <div
    class="slide-bar"
    :class="{ 'slide-in': getSliderState }"
    v-if="getSelectedContainer"
  >
    <div class="slide-slot-top" :style="[{ background: statusColor }]">
      <router-link v-if="isAdmin || isFulfillerUser" :to="'/edit/' + getSelectedContainer.id">
        <div class="top-right" style="opacity: 1;" @click="openEditMode">
          <Icon iconName="cog" size="8px" color="grey" />
        </div>
      </router-link>
      {{ getSelectedContainer.name }}
    </div>
    <div class="slide-slot-mid">
      <div class="slide-slot-mid-top">
        <h5>{{ $t('devices.title') }}</h5>
        <div v-if="showDeviceContainers || showDevices">
          <div v-if="showDeviceContainers" class="device-containers-wrapper">
            <SlideDrawerContainerItem
              v-for="(info, index) in deviceContainers"
              :id="info.container.id"
              :key="info.container.id"
              :index="index"
              :info="info"
              :isDeviceContainer="true"
            />
          </div>
          <div v-if="showDevices" class="devices-wrapper">
            <div class="this-container-alarms">
              <SlideDrawerDeviceItem
                v-for="(info, index) in devices"
                :key="info.device.id"
                :id="info.device.id"
                :index="index"
                :info="info"
                :areDeviceContainers="showDeviceContainers"
              />
            </div>
          </div>
        </div>
        <div v-else class="devices-wrapper">
          {{ $t('containers.noDevicesMsg') }}
        </div>
      </div>
      <div class="slide-slot-mid-top">
        <h5>{{ $t('devices.problems') }}</h5>
        <div v-if="showProblems" class="problems-wrapper">
          <div v-if="nestedContainersWithFall.length">
            <SlideDrawerContainerItem
              v-for="(info, index) of nestedContainersWithFall"
              :key="info.container.id"
              :id="info.container.id"
              :index="index"
              :info="info"
              :isDeviceContainer="false"
            />
          </div>
          <div v-if="nestedContainersOffline.length">
            <SlideDrawerContainerItem
              v-for="(info, index) of nestedContainersOffline"
              :key="info.container.id"
              :id="info.container.id"
              :index="index"
              :info="info"
              :isDeviceContainer="false"
            />
          </div>
        </div>
        <div v-else-if="!areNestedDevices">
          {{ $t('containers.noNestedDevicesMsg') }}
        </div>
        <div v-else class="problems-wrapper">
          {{ $t('alarms.emptyAlarmsWarnings') }}
        </div>
      </div>
      <!--          <div class="slide-slot-mid-bottom">-->
      <!--              <b-button @click="toggleCalendar(!showCal)" variant="danger" style="color: white; margin-bottom: 5px;" >-->
      <!--            {{showCal ? $t('buttons.hide') : $t('buttons.snooze')}}  {{$t('features.alerts')}}-->
      <!--              </b-button>-->
      <!--              <div class="container">-->
      <!--                  <v-date-picker-->
      <!--                    v-if="showCal"-->
      <!--                    v-model="picker"-->
      <!--                    color="success"-->
      <!--                    height="150px"-->
      <!--                    range-->
      <!--                    :no-title="true"-->
      <!--                  ></v-date-picker>-->
      <!--              </div>-->
      <!--          </div>-->
    </div>
    <div class="slide-slot slot-bottom" :style="[{ background: statusColor }]">
      <span></span>
      <b-button
        size="lg"
        variant="outline-secondary"
        @click.stop="closeSlideDrawer"
      >
        {{ $t('buttons.close') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SlideDrawerDeviceItem from './SlideDrawerDeviceItem'
// import SlideDrawerDeviceContainerItem from '@/components/slidedrawer/SlideDrawerDeviceContainerItem'
import SlideDrawerContainerItem from './SlideDrawerContainerItem'
import Icon from '@/components/elements/Icon'
import statusColorMap from '@/utils/statusColorMap'
import { isEmpty } from 'lodash'

export default {
  name: 'SlideDrawer',
  components: {
    Icon,
    SlideDrawerDeviceItem,
    SlideDrawerContainerItem
  },
  data () {
    return {
      nestedContainersWithFall: [],
      nestedContainersOffline: [],
      containerItemIds: [],
      showCal: false,
      picker: null,
      isWarningState: false,
      numStatusRequests: 0
    }
  },
  computed: {
    ...mapGetters({
      getSelectedContainer: 'containers/getSelectedContainer',
      getSliderState: 'containers/getSliderState',
      getContainerById: 'containers/getContainerById',
      getDeviceInfo: 'devices/getDeviceInfo',
      getContainerInfo: 'getContainerInfo',
      isAdmin: 'login/isAdmin',
      isFulfillerUser: 'login/isFulfillerUser'
    }),
    convertedDate () {
      return 0
    },
    containerInfo () {
      return this.getContainerInfo[this.getSelectedContainer?.id]
    },
    containerStatus () {
      return this.containerInfo?.containerStatus
    },
    statusColor () {
      return statusColorMap[this.containerStatus] || statusColorMap.noDevices
    },
    showDevices () {
      return !isEmpty(this.devices)
    },
    showDeviceContainers () {
      return !isEmpty(this.deviceContainers)
    },
    areNestedDevices () {
      return !!this.containerInfo?.nestedDeviceIds?.length
    },
    deviceContainers () {
      return this.containerInfo?.deviceInfo?.deviceContainers
    },
    devices () {
      return this.containerInfo?.deviceInfo?.devices
    },
    showProblems () {
      return !isEmpty([
        ...this.nestedContainersOffline,
        ...this.nestedContainersWithFall
      ])
    }
  },
  methods: {
    ...mapActions({
      toggleSlideDrawer: 'containers/toggleSlideDrawer',
      toggleEditingContainer: 'containers/toggleEditingContainer',
      clearSelectedContainer: 'containers/clearSelectedContainer',
      fetchRegisteredDevices: 'devices/fetchRegisteredDevices'
    }),
    openEditMode () {
      this.toggleEditingContainer({
        container: this.getSelectedContainer,
        toggle: true
      })
      this.toggleSlideDrawer(false)
    },
    // toggleCalendar (shouldShow) {
    //   this.showCal = shouldShow
    // },
    closeSlideDrawer () {
      this.toggleSlideDrawer(false)
      this.clearSelectedContainer()
    },
    checkForProblems () {
      this.nestedContainersWithFall = []
      this.nestedContainersOffline = []
      for (let device of this.containerInfo.deviceInfo?.nestedDevices) {
        if (device.status.isFall) {
          this.nestedContainersWithFall.push(device)
        } else if (device.status.isOffline) {
          this.nestedContainersOffline.push(device)
        }
      }
    }
  },
  async mounted () {
    this.checkForProblems()
  },
  renderTriggered () {
    this.checkForProblems()
  },
  watch: {
    getContainerInfo: function (newVal, oldVal) {
      this.checkForProblems()
    },
    getSelectedContainer: function (newVal, oldVal) {
      this.checkForProblems()
    }
  }
}
</script>
