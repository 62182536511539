export default {
  isLoggedIn: state => {
    return state.loggedIn
  },
  getMyIds (state, getters) {
    return state.myIds
  },
  myInfo: state => {
    return state.myInfo
  },
  myCustomers: state => {
    return state.myCustomers
  },
  myFulfillers: state => {
    return state.myFulfillers
  },
  myIntegrations: state => {
    return state.myIntegrations
  },
  getCurrentCustomer: state => {
    return state.myIds?.CUSTOMER
  },
  getCurrentCustomerNew: state => {
    return state.myIds?.CUSTOMER_NEW || []
  },
  getCurrentFulfiller: state => {
    return state.myIds?.FULFILLER
  },
  myAuthority: state => {
    return state.myIds.myAuthority
  },
  isAdmin: (state, getters, rootState) => {
    return (
      state.myInfo?.roles?.some(role =>
        rootState.knownAdminRoles.includes(role)
      ) || state.myInfo.username === 'superadmin'
    )
  },
  isFulfillerUser: (state, getters, rootState) => {
    return state.myInfo?.roles?.some(role => role === 'FULFILLER_USER')
  },
  getNewToken: state => {
    return state.newToken
  },
  getUserNew: state => {
    return state.myInfoNew
  }
}
