<template>
  <div v-if="currentIcon">
    <Icon
      :iconName="currentIcon.iconName"
      :size="size"
      :color="currentIcon.color"
    />
  </div>
</template>

<script>
import Icon from '@/components/elements/Icon'
import { iconList } from '@/assets/icons/iconList'

export default {
  name: 'Presence',
  components: {
    Icon
  },
  props: {
    presence: Boolean,
    size: String,
    containsDescription: Boolean
  },
  computed: {
    currentIcon: function () {
      if (this.presence) {
        return {
          iconName: iconList.presence.icon,
          color: 'grey'
        }
      }
      return {
        iconName: iconList.noPresence.icon,
        color: 'grey'
      }
    }
  }

}
</script>
