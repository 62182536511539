import localforage from 'localforage'

export async function saveFirebaseToken (token) {
  return await localforage.setItem('firebaseToken', token)
}

export async function getFirebaseToken () {
  return await localforage.getItem('firebaseToken')
}

export async function removeFirebaseToken () {
  return await localforage.removeItem('firebaseToken')
}
