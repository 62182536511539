export default {
  getDevices (state, getters) {
    return state.devices || []
  },
  getRegisteredDevices (state) {
    return state.registeredDevices || []
  },
  getDeviceInfo (state) {
    return state.deviceInfo || {}
  },
  getDeviceInfoById: (state) => (id) => {
    return state.deviceInfo[id]
  },
  getLatestDeviceMessage (state) {
    return state.latestDeviceMessage
  },
  getLatestOtherEventMessage (state) {
    return state.latestOtherEventMessage
  },
  getAlarmMessages (state) {
    return state.alarmMessages
  },
  getWarningMessages (state) {
    return state.warningMessages
  },
  getAlarms (state, getters) {
    const alarms = []
    // eslint-disable-next-line no-unused-vars
    for (const [deviceId, device] of Object.entries(state.deviceInfo)) {
      if (device?.status?.isFall) {
        alarms.push(device)
      }
    }
    return alarms
  },
  getWarnings (state) {
    const warnings = []
    // eslint-disable-next-line no-unused-vars
    for (const [deviceId, device] of Object.entries(state.deviceInfo)) {
      if (device?.status?.isOffline) {
        warnings.push(device)
      }
    }
    return warnings
  },
  getSimulatedEvents (state) {
    return state.simulatedEvents
  }
}
