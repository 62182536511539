<template>
  <v-app>
    <div id="app" style="min-height: 100vh; max-height: 100vh;">
      <BgOverlay />
      <Topnav />
      <Notification />
      <div class="app-min-height" :class="[loggedIn ? 'ui-grid' : '']">
        <Sidebar v-if="loggedIn" />
        <div v-if="!loggedIn"></div>
        <div
          style="padding: 15px; overflow: auto; max-height: calc(100vh-61px);"
        >
          <div class='alert' v-if="!notificationsPermission">
            <div class='warning-message'>
              <b-icon icon="exclamation-circle" style="margin-right: 10px;"></b-icon>
              {{ notificationPermissionMessage }}
            </div>
          </div>
          <router-view />
        </div>
      </div>
      <SlideDrawer v-if="shouldShowSlideDrawer" />
      <SlideDrawerEditRule v-if="communityRuleForEdit"/>
      <div class="fixed-tag">{{ codeVersion }}</div>
    </div>
  </v-app>
</template>

<script>
import Sidebar from '@/components/layout/sidebar/Sidebar'
import Topnav from '@/components/layout/topnav/Topnav'
import SlideDrawer from '@/components/slidedrawer/SlideDrawer'
import BgOverlay from '@/components/layout/BgOverlay'
import Notification from '@/components/layout/Notification'
import SlideDrawerEditRule from '@/components/slidedrawer/SlideDrawerEditRule/SlideDrawerEditRule'
import { mapGetters, mapActions } from 'vuex'
import Vue from 'vue'
import { isEmpty } from 'lodash'
import messaging from './plugins/firebase/firebase'
import * as notificationService from './services/notificationService'
import { fcmSubscribe } from './api/users/index'
import firebase from 'firebase/app'

export default {
  data () {
    return {
      codeVersion: ''
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'login/isLoggedIn',
      myInfo: 'login/myInfo',
      getContainers: 'containers/getContainers',
      getLatestDeviceMessage: 'devices/getLatestDeviceMessage',
      getLatestOtherEventMessage: 'devices/getLatestOtherEventMessage',
      getSelectedContainer: 'containers/getSelectedContainer',
      getRegisteredDevices: 'devices/getRegisteredDevices',
      getDeviceInfo: 'devices/getDeviceInfo',
      newToken: 'login/getNewToken',
      communityRuleForEdit: 'notifications/communityRuleForEdit'
    }),
    shouldShowSlideDrawer () {
      return (
        !isEmpty(this.getSelectedContainer) &&
        !!this.getSelectedContainer.id &&
        this.loggedIn
      )
    },
    notificationsPermission () {
      return window.Notification?.permission === 'granted'
    },
    notificationPermissionMessage () {
      if (!firebase.messaging.isSupported()) {
        return 'Your browser is currently not supported for receiving the Notifications such as Fall Alerts & Smart Insights. Please use the latest version of Chome, Opera, or Firefox.'
      }
      return 'In order to receive notifications such as fall alerts & smart insights, your browser notifications need to be turned on'
    }
  },
  methods: {
    ...mapActions({
      doLogout: 'login/doLogout',
      fetchContainers: 'containers/fetchContainers',
      fetchRegisteredDevices: 'devices/fetchRegisteredDevices',
      fetchMyInfo: 'login/fetchMyInfo',
      resetImportState: 'containers/resetImportState',
      checkForIntegrations: 'login/checkForIntegrations',
      listenToDevices: 'devices/listenToDevices',
      getAvailableCustomersandFulfillers:
        'login/getAvailableCustomersandFulfillers',
      addNewSimulatedSmartAlert: 'devices/addNewSimulatedSmartAlert'
    })
  },
  async mounted () {
    const versionMeta = document.querySelector('[name="project-version"]')
    if (versionMeta !== null) {
      this.codeVersion = versionMeta.getAttribute('content')
    }
    if (this.loggedIn) {
      this.getAvailableCustomersandFulfillers()
      this.fetchMyInfo()
      this.checkForIntegrations()
      Vue.$socketApi.reconnect()
      this.listenToDevices()
      this.fetchContainers()
    }
  },
  watch: {
    loggedIn: async function (newVal, oldVal) {
      if (newVal === false) {
        if (this.myInfo) await this.doLogout()
        return this.$router.push('/login')
      } else {
        if (messaging) {
          messaging
            .getToken({ vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY })
            .then(currentToken => {
              if (currentToken) {
                notificationService.saveFirebaseToken(currentToken)
                fcmSubscribe(currentToken, 'fall', 'subscribe')
                fcmSubscribe(currentToken, 'smart', 'subscribe')
              }
            }).catch((error) => {
              throw error
            })
        }
        await this.checkForIntegrations()
        await Vue.$socketApi.reconnect()
        this.listenToDevices()
      }
    },
    myInfo: async function (newVal, oldVal) {
      await this.getAvailableCustomersandFulfillers()
      if (this.loggedIn && newVal?.myId) {
        await this.fetchContainers()
        await this.checkForIntegrations()
        await Vue.$socketApi.reconnect()
        this.listenToDevices()
      }
    },
    getLatestDeviceMessage: async function (newVal, oldVal) {
    },
    getLatestOtherEventMessage: async function (newVal, oldVal) {
      if (newVal?.meta?.event?.did === 'import-amplify-org') {
        this.resetImportState()
        if (newVal?.meta?.event?.status === 'SUCCESS') {
          await this.fetchContainers()
        }
      }
    },
    $route: {
      immediate: true,
      handler (to, from) {
        document.title = `${to.meta.i18n ? this.$t(to.meta.i18n) + ' - ' : ''}${this.$t('title')}`
      }
    },
    async newToken (newVal) {
      if (newVal) {
        this.getAvailableCustomersandFulfillers()
      }
    }
  },
  components: {
    Sidebar,
    Topnav,
    SlideDrawer,
    BgOverlay,
    Notification,
    SlideDrawerEditRule
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
@import '@/assets/scss/slidedrawer.scss';
@import '@/assets/scss/login.scss';
@import '@/assets/scss/register.scss';
@import '@/assets/scss/editcontainer.scss';
html {
  overflow: auto;
}

.warning-message {
  width: 900px;
  padding: 20px;
  border-radius: 10px;
  color: black;
  background-color: #FFBC82;
}

.alert {
  width:100%;
  display: flex;
  justify-content: center;
}

#app {
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.app-min-height {
  height: calc(100% - 61px);
  overflow: auto;
}
.ui-grid {
  overflow: auto;
  position: relative;
  display: grid;
  grid-template-columns: 260px auto;
}
.nav-grid {
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: 260px auto auto;
}

@media all and (max-width: 780px) {
  .ui-grid {
    grid-template-columns: 60px auto;
  }
  .nav-grid {
    grid-template-columns: 50px auto auto;
  }
}
</style>
