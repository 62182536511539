import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/Alarms/Alarms.vue'),
    meta: {
      requiresAuthentication: true,
      i18n: 'navigation.alarms'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About/About.vue')
  },
  {
    path: '/alarms',
    name: 'Alarms',
    component: () =>
      import(/* webpackChunkName: "alarms" */ '../views/Alarms/Alarms.vue'),
    meta: {
      requiresAuthentication: true,
      i18n: 'navigation.alarms'
    }
  },
  {
    path: '/facility',
    name: 'Facility',
    component: () =>
      import(
        /* webpackChunkName: "facility" */ '../views/Facility/Facility.vue'
      ),
    meta: {
      requiresAuthentication: true,
      requiresAuthorization: true,
      i18n: 'navigation.facility'
    }
  },
  {
    path: '/device-registration',
    name: 'DeviceRegistration',
    component: () =>
      import(
        /* webpackChunkName: "DeviceReg" */ '../views/Registration/Devices.vue'
      ),
    meta: {
      requiresAuthentication: true,
      requiresAuthorization: true,
      i18n: 'navigation.deviceReg'
    }
  },
  {
    path: '/device-inventory',
    name: 'DeviceInventory',
    component: () =>
      import(
        /* webpackChunkName: "DeviceReg" */ '../views/Inventory/Devices.vue'
      ),
    meta: {
      requiresAuthentication: true,
      requiresAuthorization: true,
      i18n: 'navigation.deviceInventory'
    }
  },
  {
    path: '/containers/:id?',
    name: 'Containers',
    component: () =>
      import(
        /* webpackChunkName: "containers" */ '../views/Containers/Containers.vue'
      ),
    props: route => ({ params: route.params }),
    meta: {
      requiresAuthentication: true,
      i18n: 'navigation.dashboard'
    }
  },
  {
    path: '/edit/:id',
    name: 'EditContainer',
    component: () =>
      import(
        /* webpackChunkName: "EditContainer" */ '../views/Containers/Edit.vue'
      ),
    props: { id: route => ({ params: route.params }) },
    meta: {
      requiresAuthentication: true,
      requiresAtLeastFulfillerUser: true,
      i18n: 'navigation.dashboard'
    }
  },
  {
    path: '/containers/',
    name: 'Containers-Main',
    component: () =>
      import(
        /* webpackChunkName: "containers" */ '../views/Containers/Containers.vue'
      ),
    props: true,
    meta: {
      requiresAuthentication: true,
      i18n: 'navigation.dashboard'
    }
  },
  {
    path: '/user-management',
    name: 'User',
    component: () =>
      import(/* webpackChunkName: "user" */ '../views/User/User.vue'),
    meta: {
      requiresAuthentication: true,
      i18n: 'navigation.userManagement'
    }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ '../views/Notifications/Notifications.vue'
      ),
    meta: {
      requiresShowNotification: true,
      i18n: 'navigation.notifications'
    }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () =>
      import(/* webpackChunkName: "reports" */ '../views/Reports/Reports.vue'),
    meta: {
      requiresAuthentication: true,
      requiresAuthorization: true
    }
  },
  {
    path: '/audit',
    name: 'Audit',
    component: () =>
      import(/* webpackChunkName: "reports" */ '../views/Audit/Audit.vue'),
    meta: {
      requiresAuthentication: true,
      requiresHigherAdmin: true,
      i18n: 'navigation.eventLog'
    }
  },
  {
    path: '/overview',
    name: 'Overview',
    component: () =>
      import(
        /* webpackChunkName: "overview" */ '../views/Overview/Overview.vue'
      ),
    meta: {
      requiresAuthentication: true
    }
  },
  {
    path: '/accounts-management',
    name: 'Integrations',
    component: () =>
      import(
        /* webpackChunkName: "integrations" */ '../views/Integrations/Integrations.vue'
      ),
    meta: {
      requiresAuthentication: true,
      requiresHigherAdmin: true,
      i18n: 'navigation.accountsManagement'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Login/Login.vue'),
    meta: {
      i18n: 'features.login'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Register/Register.vue'),
    props: { email: route => ({ params: route.params }) },
    meta: {
      i18n: 'features.register'
    }

  },
  {
    path: '/alerts',
    name: 'Alerts',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Alerts/Alerts.vue'),
    meta: {
      requiresAuthentication: true,
      i18n: 'navigation.alert'
    }
  },
  {
    path: '/pauses',
    name: 'Pauses',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Pauses/Pauses.vue'),
    meta: {
      requiresAuthentication: true,
      i18n: 'navigation.pause'
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach(async (to, from, next) => {
  const myInfo = store?.modules?.login?.state?.myInfo
  const loggedIn = store?.modules?.login?.state?.loggedIn
  if (to.matched.some(record => record.meta.requiresAuthorization)) {
    if (myInfo) {
      if (
        myInfo.roles.some(role => store?.state?.knownAdminRoles?.includes(role))
      ) {
        next()
        return
      }

      next(from)
      return
    }
  }
  if (to.matched.some(record => record.meta.requiresHigherAdmin)) {
    if (myInfo) {
      if (
        myInfo.roles.some(role =>
          store?.state?.knownUpperAdminRoles?.includes(role)
        )
      ) {
        next()
        return
      }
      next(from)
      return
    }
  }
  if (to.matched.some(record => record.meta.requiresAtLeastFulfillerUser)) {
    if (myInfo) {
      if (
        myInfo.roles.some(role =>
          store?.state?.knownFulfillerUser?.includes(role)
        )
      ) {
        next()
        return
      }
      next(from)
      return
    }
  }
  if (to.matched.some(record => record.meta.requiresAuthentication)) {
    if (loggedIn) {
      next()
      return
    }
    next('/login')
    return
  }
  if (to.matched.some(record => record.meta.requiresShowNotification)) {
    let currentURL = window.location.href
    let shouldShowNotification = (currentURL.includes('.dele.ai') || currentURL.includes('localhost')) && !currentURL.includes('dbm-us')
    let requiresAuthorization = myInfo?.roles.some(role => store?.state?.knownAdminRoles?.includes(role))
    if (shouldShowNotification && requiresAuthorization) {
      next()
      return
    }
    next(from)
    return
  }
  next()
})

export default router
