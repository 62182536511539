export const removeAlert = ({ commit, dispatch }, index) => {
  try {
    commit('REMOVE_ALERT', index)
  } catch (err) {
    console.log(err)
    throw err
  }
}
export const addAlert = ({ commit, dispatch }, alert) => {
  try {
    commit('ADD_ALERT', alert)
  } catch (err) {
    console.log(err)
    throw err
  }
}
export const resetState = ({ commit }) => {
  commit('devices/RESET_STATE')
  commit('containers/RESET_STATE')
  commit('login/RESET_STATE')
}
