import config from './config'

export default {
  RESET_STATE (state, payload) {
    for (const key of Object.keys(config.state())) {
      state[key] = config.state()[key]
    }
  },
  LOGIN (state, payload) {
    state.loggedIn = true
  },
  SET_TOKEN (state, payload) {
    state.newToken = payload
  },
  LOGOUT (state, payload) {
    state.loggedIn = false
    state.myInfo = null
  },
  SET_MY_INFO (state, payload) {
    const ids = {}
    for (const authority in payload.authorities) {
      ids[authority] = payload.authorities[authority]
    }
    state.myIds = ids
    state.myInfo = payload
  },
  SET_MY_INFO_NEW (state, payload) {
    state.myInfoNew = payload
  },
  SET_ACTIVE_CUSTOMER (state, payload) {
    const customers = payload ? [payload] : []
    state.myIds = { ...state.myIds, CUSTOMER: customers }
  },
  SET_ACTIVE_CUSTOMER_NEW (state, payload) {
    const customers = payload ? [payload] : []
    state.myIds = { ...state.myIds, CUSTOMER_NEW: customers }
  },
  SET_ACTIVE_FULFILLER (state, payload) {
    state.myIds = { ...state.myIds, FULFILLER: [payload] }
  },
  SET_ACTIVE_TENANT (state, payload) {
    state.activeTenant = payload
  },
  SET_MY_AUTHORITY (state, payload) {
    state.myIds.myAuthority = payload
  },
  SET_MY_CUSTOMERS (state, payload) {
    state.myCustomers = payload
  },
  ADD_TO_MY_CUSTOMERS (state, payload) {
    let myCustomers = state.myCustomers
    myCustomers.push(payload)
    state.myCustomers = myCustomers
  },
  ADD_TO_MY_FULFILLERS (state, payload) {
    let myFulfillers = state.myFulfillers
    myFulfillers.push(payload)
    state.myFulfillers = myFulfillers
  },
  SET_MY_FULFILLERS (state, payload) {
    state.myFulfillers = payload
  },
  SET_MY_INTEGRATIONS (state, payload) {
    state.myIntegrations = payload || []
  },
  SET_EVENT_RULESET (state, payload) {
    state.eventRuleset = payload || []
  }
}
