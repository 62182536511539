import { isEqual } from 'lodash'
export default {
  REMOVE_ALERT (state, obj) {
    state.alerts = state.alerts.filter(alert => {
      return !isEqual(alert, obj)
    })
  },
  ADD_ALERT (state, alert) {
    state.alerts.push(alert)
  }
}
