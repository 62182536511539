export default {
  state: () => ({
    alerts: [],
    availableLanguages: [
      {
        code: 'en',
        name: 'English'
      },
      {
        code: 'fr',
        name: 'Français'
      }
    ],
    knownAdminRoles: ['CUSTOMER_ADMIN', 'FULFILLER_ADMIN', 'SUPERADMIN'],
    knownFulfillerUser: [
      'FULFILLER_USER',
      'CUSTOMER_ADMIN',
      'FULFILLER_ADMIN',
      'SUPERADMIN'
    ],
    knownUpperAdminRoles: ['FULFILLER_ADMIN', 'SUPERADMIN']
  })
}
