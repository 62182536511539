<template>
  <span :class="alertType">
    {{ type }}
  </span>
</template>

<script>
export default {
  props: ['type'],
  computed: {
    alertType () {
      switch (this.type) {
        case 'FALL':
          return 'fall'
        case 'SMART':
          return 'smart'
        default:
          return ''
      }
    }
  }
}
</script>

<style scoped>
span {
  font-size: 12px;
  display: inline-block;
  min-width: 70px;
  text-align: center;
  color: #fff;
  background-color: rgb(150, 150, 150);
  padding: 0.5em 1.2em;
  margin-right: 1em;
  border-radius: 5px;
}
.fall {
  background-color: rgb(235, 49, 49);
}
.smart {
  background-color: rgb(252, 178, 42);
}
</style>
