import { RULESET } from './ruleset-enums'

const addTransformedRulesetLabel = (ruleset) => {
  return {
    ...ruleset,
    transformedLabel: transformStatusSubtypeEnum(ruleset.label)
  }
}

const addTransformedRulesetsLabels = (rulesets) => {
  return rulesets.map(ruleset => addTransformedRulesetLabel(ruleset))
}

const transformStatusSubtypeEnum = (subtype) => {
  switch (subtype) {
    case RULESET.FALL_REPORTED:
      return 'Fall - Reported'
    case RULESET.FALL_DETECTED:
      return 'Fall - Detected'
    case RULESET.IN_BED:
      return 'In bed'
    case RULESET.OUT_OF_BED:
      return 'Out of bed'
    case RULESET.IN_BATHROOM:
      return 'In the bathroom'
    case RULESET.DWELL_IN_BATHROOM:
      return 'In the bathroom for {X} time'
    case RULESET.LEFT_THE_APARTMENT:
      return 'Left the apartment'
    default:
      return 'Not defined'
  }
}

export { addTransformedRulesetsLabels, addTransformedRulesetLabel }
