import Vue from 'vue'
import * as helpers from '../helpers'
import * as axios from 'axios'
import CrudMsgProcessor from '@/components/notifications/crud_msgs'
import ServerMsgProcessor from '@/components/notifications/server_msgs'
import { API_ROOT, NOTIFICATION_API_ROOT } from '@/config'
import { getMyIds } from '../helpers'

export const getDevices = async id => {
  const data = {
    containment: 'uncontained'
  }
  let response
  let url = `${helpers.buildBaseUrl()}/devices`
  if (id) url = `${url}/${id}`
  try {
    response = await Vue.axios.get(url, { params: data })
    return response
  } catch (err) {
    handleErr(err)
    throw err
  }
}

export const getRegisteredDevices = async () => {
  const data = {
    containment: 'contained'
  }
  let url = `${helpers.buildBaseUrl()}/devices`
  try {
    let response = await Vue.axios.get(url, { params: data })
    return response
  } catch (err) {
    handleErr(err)
    throw err
  }
}

export const deleteDevice = async data => {
  let response
  let url = `${helpers.buildBaseUrl()}/devices/${data.id}`
  try {
    response = await Vue.axios.delete(url)
    CrudMsgProcessor('device_delete', data.metadata?.serialNumber)
    return response
  } catch (err) {
    handleErr(err)
    throw err
  }
}

export const addDeviceToInventory = async data => {
  let response
  let url = `${helpers.buildBaseUrl()}/devices`
  try {
    response = await Vue.axios.post(url, data)
    CrudMsgProcessor('device_add', data.metadata?.serialNumber)
    return response
  } catch (err) {
    handleErr(err)
    throw err
  }
}

export const upload = async (formData, path) => {
  let response
  const url = `${helpers.buildBaseUrl()}${path}`
  try {
    response = await axios.post(url, formData, {
      headers: {
        Accept: 'text/csv'
      }
    })
    CrudMsgProcessor('device_upload')
    return response
  } catch (err) {
    handleErr(err)
    throw err
  }
}

export const getReportData = async data => {
  const myIds = getMyIds()
  let params = data
  if (myIds?.CUSTOMER) {
    params.customerId = myIds.CUSTOMER[0]
  }
  if (myIds?.FULFILLER) {
    params.fulfillerId = myIds.FULFILLER[0]
  }
  if (params.customerId || params.fulfillerId) {
    try {
      let response = await Vue.axios.get(`${API_ROOT}/events`, {
        params: params
      })
      console.log('Report Response: ', response)
      return response
    } catch (err) {
      handleErr(err)
      throw err
    }
  }
}

export const getDataReport = async (data, customerID) => {
  try {
    let response = await Vue.axios.get(
      `${NOTIFICATION_API_ROOT}/api/notifications/customer`,
      {
        params: data,
        headers: { clientid: customerID }
      }
    )
    return response
  } catch (error) {
    ServerMsgProcessor('', error)
    return error
  }
}

export const getDataReportExport = async (data, customerID) => {
  try {
    let response = await Vue.axios.get(
      `${NOTIFICATION_API_ROOT}/api/notifications/customer/export`,
      {
        params: data,
        headers: { clientid: customerID }
      }
    )
    return response.data
  } catch (error) {
    return true
  }
}

export const resolveEvent = async (params, id) => {
  const myIds = getMyIds()
  let config = { headers: {} }
  let data = params
  if (myIds?.FULFILLER?.[0]) {
    config.headers['X-Delehealth-FulfillerId'] = myIds?.FULFILLER?.[0]
    data.fulfillerId = myIds?.FULFILLER?.[0]
  }
  if (myIds?.CUSTOMER?.[0]) {
    config.headers['X-Delehealth-CustomerId'] = myIds?.CUSTOMER?.[0]
  }

  try {
    let response = await Vue.axios.post(
      `${API_ROOT}/events/${id}/resolution`,
      data,
      { ...config }
    )
    return response
  } catch (err) {
    handleErr(err)
    throw err
  }
}

export const postDemoEvent = async data => {
  try {
    await Vue.axios.post(`${API_ROOT}/demo-events`, data)
  } catch (err) {
    handleErr(err)
    throw err
  }
}

export const postDemoSmartEvent = async (deviceId) => {
  await Vue.axios.post(`${API_ROOT}/simulate-smart`, { deviceId })
    .catch(() => {
      CrudMsgProcessor('simulateSmartAlertError')
    })
}

export const getAlertData = async (data) => {
  const myIds = getMyIds()
  const params = data
  if (myIds?.CUSTOMER) {
    params.customerId = myIds.CUSTOMER[0]
  }
  if (myIds?.FULFILLER) {
    params.fulfillerId = myIds.FULFILLER[0]
  }
  if (params.customerId || params.fulfillerId) {
    try {
      const response = await Vue.axios.get(`${API_ROOT}/events/alertsExtended`, {
        params: params
      })
      console.log('Report Response: ', response)
      return response
    } catch (err) {
      handleErr(err)
      throw err
    }
  }
}

function handleErr (err) {
  console.log(err, 'handling ERRRR')
  err?.response?.status
    ? CrudMsgProcessor('err', err)
    : ServerMsgProcessor('', err)
}
