import api from '@/api'
import { appendNestedInfo } from '@/utils/containersHelper'
import { cloneDeep } from 'lodash'
import { getMyIds } from '@/api/helpers'

export const fetchContainers = async ({ commit, dispatch, state }) => {
  let response = []
  try {
    let roots = await api.containers.getRootContainers()
    const myIds = getMyIds()
    const availableRoot = await handleRootContainer(
      roots,
      myIds,
      state?.currentRoot?.id
    )
    if (availableRoot?.usableId) {
      response = await Promise.all([
        await api.containers.getContainers(availableRoot.usableId),
        await api.containers.getContainerAsList(availableRoot.usableId)
      ])
    } else {
      // roots = []
      response = []
    }
    if (commit) {
      commit('SET_CONTAINERS', cloneDeep(response[0] || []))
      commit('SET_ROOTS', roots || [])
      commit(
        'SET_CONTAINERS_AS_OBJECT',
        appendNestedInfo(cloneDeep(response[1] || []))
      )
      if (response) {
        await dispatch('devices/fetchRegisteredDevices', null, { root: true })
      }
    }
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const fetchContainersAsList = async ({ commit, state }) => {
  const roots = await api.containers.getRootContainers()
  const myIds = getMyIds()
  console.log('Calling Handle Root Container')
  const availableRoot = await handleRootContainer(
    roots,
    myIds,
    state.currentRoot?.id
  )
  if (availableRoot?.usableId) {
    const res = await api.containers.getContainerAsList(availableRoot.usableId)
    commit('SET_CONTAINERS_AS_OBJECT', res)
  } else {
    commit('SET_CONTAINERS_AS_OBJECT', {})
  }
}

const handleRootContainer = async (roots, myIds, currRoot) => {
  console.log('handling root container', currRoot)
  let matchedRoots = {
    fulfillerRoots:
      myIds?.FULFILLER &&
      roots.filter(item => myIds?.FULFILLER.includes(item?.fulfillerId)),
    customerRoots:
      myIds?.CUSTOMER &&
      myIds?.FULFILLER &&
      roots.filter(
        item =>
          myIds?.CUSTOMER.includes(item?.customerId) &&
          myIds?.FULFILLER.includes(item?.fulfillerId)
      )
  }
  matchedRoots.usableRoot = currRoot
    ? { id: currRoot }
    : matchedRoots.customerRoots?.[0]
  matchedRoots.usableId = matchedRoots.usableRoot
    ? matchedRoots.usableRoot.id
    : null
  return matchedRoots
}

export const chooseRootContainer = async ({ commit, dispatch }, newRoot) => {
  let response = []
  try {
    response = await Promise.all([
      await api.containers.getContainers(newRoot.id),
      await api.containers.getContainerAsList(newRoot.id)
    ])
    commit('SET_ROOT_CONTAINER', cloneDeep(newRoot))
    commit('SET_CONTAINERS', cloneDeep(response[0] || []))
    commit(
      'SET_CONTAINERS_AS_OBJECT',
      appendNestedInfo(cloneDeep(response[1] || []))
    )
    if (response) {
      await dispatch('devices/fetchRegisteredDevices', null, { root: true })
    }
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const chooseCustomer = async ({ commit, dispatch }, ids) => {
  try {
    ids[0] && commit('login/SET_ACTIVE_CUSTOMER', ids[0], { root: true })
    commit('login/SET_ACTIVE_CUSTOMER_NEW', ids[1], { root: true })
    dispatch('devices/listenToDevices', null, { root: true })
    commit('SET_ROOT_CONTAINER', {})
    return ids[0]
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const chooseFulfiller = async ({ commit, dispatch }, fulfiller) => {
  try {
    commit('login/SET_ACTIVE_FULFILLER', fulfiller.id, { root: true })
    commit('login/SET_ACTIVE_TENANT', fulfiller, { root: true })
    dispatch('devices/listenToDevices', null, { root: true })
    return fulfiller.id
  } catch (err) {
    console.log(err)
    throw err
  }
}
export const switchCustomerOptions = async (
  { commit, dispatch },
  fulfiller
) => {
  try {
    const customerOpts = await api.users.getAvailableCustomers(fulfiller.id)
    const clientsOpts = await api.users.getClients(fulfiller.tenantPropsNew?.clientId)
    if (customerOpts && clientsOpts) {
      const customers = customerOpts.map(customer => {
        if (clientsOpts.length) {
          const client = clientsOpts.find(client => {
            if (client.customerIdOld === customer.id) {
              return client
            }
          })
          const customerWithAdditionProps = {
            ...customer,
            clientPropsNew: { ...client }
          }
          return customerWithAdditionProps
        } else {
          return customer
        }
      })
      if (!customers.length) {
        commit('login/SET_ACTIVE_CUSTOMER_NEW', null, { root: true })
      }
      commit('login/SET_MY_CUSTOMERS', customers, { root: true })
      commit('SET_ROOT_CONTAINER', {})
      console.log('Customers after changing fulfiller', customers)
      return customers
    }
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const fetchContainer = async ({ commit, dispatch }, id) => {
  try {
    const response = await api.containers.getContainer(id)
    commit('SET_SELECTED_CONTAINER', { container: response, show: false })
    return response
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const setSelectedContainer = async ({ commit, dispatch }, id) => {
  try {
    commit('SET_SELECTED_CONTAINER', { container: { id }, show: true })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const clearSelectedContainer = async ({ commit, dispatch }) => {
  try {
    commit('CLEAR_SELECTED_CONTAINER')
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const updateContainer = async (
  { commit, dispatch },
  { container, msgType, updatedObj, name }
) => {
  try {
    await api.containers.putContainer(container, msgType, updatedObj, name)
    await fetchContainers({ commit, dispatch })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const postContainer = async ({ commit, dispatch }, container) => {
  try {
    const response = await api.containers.postContainer([container])
    const res = await api.containers.getContainers(response[0].id)
    commit('SET_CONTAINERS', res)
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const toggleSlideDrawer = ({ commit, dispatch }, toggle) => {
  try {
    commit('TOGGLE_SLIDE_DRAWER', toggle.open)
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const toggleEditingContainer = ({ commit, dispatch }, data) => {
  try {
    commit('SET_TOGGLE_EDITING_CONTAINER', {
      editData: data.data,
      toggle: data.toggle
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const doImportAmplify = async ({ commit }, importType) => {
  try {
    await api.containers.importAmplifyTree(importType)
    commit('SET_IMPORT_STARTED', true)
  } catch (err) {
    console.log(err)
    commit('SET_IMPORT_STARTED', false)
    throw err
  }
}

export const downloadContainerTree = async ({ commit }, rootId) => {
  try {
    return await api.containers.downloadAmplifyTree(rootId)
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const resetImportState = async ({ commit }) => {
  commit('SET_IMPORT_STARTED', false)
}
