<template>
  <div>
    <div v-if="!currentIcon.isSvg">
      <Icon
        :iconName="currentIcon.iconName"
        :size="size"
        :color="currentIcon.color"
      />
    </div>
    <div v-if="currentIcon.isSvg">
      <img :src="currentIcon.iconName" :style="'height:'+size">
    </div>
  </div>
</template>

<script>
import Icon from '@/components/elements/Icon'
import { iconList } from '@/assets/icons/iconList'
export default {
  name: 'ResidentIcon',
  components: {
    Icon
  },
  props: {
    residentFall: Boolean,
    size: String,
    containsDescription: Boolean
  },
  computed: {
    currentIcon: function () {
      if (!this.residentFall) {
        return {
          iconName: iconList.offline.icon,
          isSvg: true,
          color: 'dodgerblue',
          description: 'Radar'
        }
      } else if (this.residentFall) {
        return {
          iconName: iconList.fallingHuman.icon,
          isSvg: true,
          color: 'red',
          description: 'Fall Detected'
        }
      } else {
        return {
          iconName: iconList.fallingHuman.icon,
          isSvg: true,
          color: 'red',
          description: 'Fall Detected'
        }
      }
    }
  }
}
</script>
