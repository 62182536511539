import Mqtt from 'mqtt'
import { getToken } from './authService'

export default class {
  constructor (connection, options) {
    this.connection = connection
    this.opts = options
    this.opts.reconnectPeriod = 2000
    this.unInitedListeners = []
    if (typeof connection === 'string') {
      if (this.Mqtt) {
        this.reconnect()
      } else {
        this.connect(connection, options)
      }
    } else {
      this.Mqtt = connection
    }

    if (options.store) {
      this.store = options.store
    }
  }

  checkConnection (status) {
    if (status === false) {
      this.reconnect()
    }
  }

  setupHandlers () {
    this.Mqtt.on('message', (topic, payload, packet) => {
      const message = JSON.parse(String.fromCharCode.apply(null, payload))
      // message CAN be null so only dispatch if it isn't
      console.log('MessageTime: ', message, topic, payload)
      if (!message) {
        console.log('Event received but not dispatched - NULL MESSAGE.')
        console.log('topic: ', topic)
        console.log('payload: ', payload)
        console.log('packet: ', packet)
      }
      if (!topic) {
        console.log('Event received but not dispatched - NULL TOPIC.')
        console.log('topic: ', topic)
        console.log('payload: ', payload)
        console.log('packet: ', packet)
      }
      if (topic && message) {
        if (!this.store) console.log('Event received but not dispatched - no store.')
        if (this.store) this.store.dispatch('devices/handleNewSocketMessage', { topic, message, packet })
      }
    })

    this.Mqtt.on('connect', () => {
      console.log(new Date().toISOString(), `${this.connection} connected.`)
      // resubscribe to any known topics
      this.resubscribe()
      const listeners = this.unInitedListeners
      for (let i = listeners.length - 1; listeners.length < 0; i--) {
        if (listeners[i] && listeners[i].topic && listeners[i].id) {
          const inited = this.initListener(listeners[i].topic, listeners[i].id)
          if (inited) listeners.pop()
        }
      }
      this.unInitedListeners = listeners
    })

    this.Mqtt.on('disconnect', (args) => {
      console.log(new Date().toISOString(), `${this.connection} attempting to reconnect.`)
      this.reconnect()
    })

    this.Mqtt.on('offline', (args) => {
      console.log(new Date().toISOString(), `${this.connection} appears offline.`)
    })

    this.Mqtt.on('error', (error) => {
      if (/Not authorized/.test(error.message)) {
        console.log(new Date().toISOString(), `${this.connection} Not Authorized.`)
      }
      if (/Error in connection establishment/.test(error.message)) {
        this.reconnect()
      }
      console.log('Socket Error:', error)
    })

    this.Mqtt.on('close', () => {
      this.resubscribe()
      console.log(new Date().toISOString(), `${this.connection} connection closed.`)
    })
  }

  initListener (topic, id) {
    console.log('init listener: topic:', topic, 'id:', id)
    // call reconnect just in case we're dropped.  it'll only attempt to connect if necessary
    // this.reconnect()
    try {
      if (id) this.Mqtt.publish(topic, JSON.stringify({ _id: id }))
      console.log('about to subscribe to topic: ', topic)
      this.Mqtt.subscribe(topic, { qos: 2 })
      console.log('subscribed to topic: ', topic)
      if (this.store) this.store.dispatch('devices/addMessageListener', topic)
    } catch (err) {
      console.log(err)
      this.unInitedListeners.push({ topic, id })
    }
  }

  sendMessage (topic, packet) {
    console.log('Mqtt Publish topic packet: ', JSON.stringify(packet))
    this.Mqtt.publish(topic, JSON.stringify(packet))
  }

  connect (connection, options) {
    const opts = options || this.opts
    const conn = connection || this.connection
    if (this.Mqtt && this.Mqtt.end) this.Mqtt.end({ force: true })
    if (opts.password && opts.password.length) {
      this.Mqtt = Mqtt.connect(conn, opts)
      this.setupHandlers()
    }
  }

  async resubscribe () {
    const self = this
    // first init from store
    console.log('resubscribing to known topics')
    await this.store.dispatch('devices/listenToDevices')
    for (const topic in this.Mqtt._resubscribeTopics) {
      if (self.Mqtt._resubscribeTopics.hasOwnProperty(topic)) self.Mqtt.subscribe(topic, { qos: 2 })
    }
  }

  async reconnect () {
    const self = this
    const password = await getToken()
    let attemptingToConnect = false
    if (password) {
      console.log('reconnecting')
      try {
        if (!self.Mqtt || !self.Mqtt.connected) {
          attemptingToConnect = true
          self.opts.password = Buffer.from(password)
          return self.connect(self.connection, self.opts)
        }
        if (!attemptingToConnect && !self.Mqtt.reconnecting) {
          self.Mqtt.options.username = 'Bearer'
          self.Mqtt.options.password = password
          self.Mqtt.reconnect()
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  disconnect () {
    this.Mqtt.end(res => {
      console.log('ended listening', res)
    })
  }
}
