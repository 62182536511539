import Vue from 'vue'
import Vuex from 'vuex'
import { v4 } from 'uuid'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import storeConfig from './store'
import localforage from 'localforage'
import {
  SOCKET_ROOT,
  AUTHENTICATION_API_ROOT,
  FCM_API_ROOT,
  CUSTOMER_MENAGEMENT_API_ROOT,
  NOTIFICATION_API_ROOT,
  EVENT_RULESET_API_ROOT
} from './config'
import SocketService from './services/socketService'
import * as authService from './services/authService'
import { sync } from 'vuex-router-sync'
import { refreshNewToken, fcmSubscribe } from './api/users/index'

// import { makeRestServer } from './mockRestServer'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { getToken, getNewToken } from './services/authService'
import firebaseMessaging from './plugins/firebase/firebase'
import jwtDecode from 'jwt-decode'
import * as notificationService from './services/notificationService'

const authenticationApi = `${AUTHENTICATION_API_ROOT}/api/users/login`
const authenticationRegex = new RegExp(authenticationApi)

const fcmApi = `${FCM_API_ROOT}/api/fcm`
const fcmApiRegex = new RegExp(fcmApi)

const customerMenagementCustomersApi = `${CUSTOMER_MENAGEMENT_API_ROOT}/api/customers`
const customerMenagementApiCustomersRegex = new RegExp(
  customerMenagementCustomersApi
)

const customerManagementRoomsApi = `${CUSTOMER_MENAGEMENT_API_ROOT}/api/rooms`
const customerManagementRoomsApiRegex = new RegExp(
  customerManagementRoomsApi
)

const customerMenagementTenantsApi = `${CUSTOMER_MENAGEMENT_API_ROOT}/api/tenants`
const customerMenagementApiTenantsRegex = new RegExp(
  customerMenagementTenantsApi
)

const notificationsApi = `${NOTIFICATION_API_ROOT}/api/notifications/customer`
const notificationsApiRegex = new RegExp(notificationsApi)

const rulesetApi = `${EVENT_RULESET_API_ROOT}/api/rulesets`
const rulesetApiRegex = new RegExp(rulesetApi)

Vue.prototype.$messaging = firebaseMessaging

Vue.config.productionTip = false

localforage.config({
  driver: localforage.LOCALSTORAGE,
  name: 'igt-dashboard-ui'
})

Vue.use(Vuex)
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
export const store = new Vuex.Store(storeConfig)
sync(store, router)
if (
  process.env.NODE_ENV !== 'production' &&
  !process.env.VUE_APP_LOCAL_DEV_STACK
) {
  console.log('starting mirage because NODE_ENV=', process.env.NODE_ENV)
  // makeRestServer()
}

// These interceptors will be used later to handle things like Authorization headers
Vue.axios.interceptors.request.use(
  async (config) => {
    const token = await getToken()
    const newToken = await getNewToken()
    if (/\/public\/tokens/.test(config.url)) {
      delete config.headers['Authorization']
      return config
    }
    if (/\/public\/users/.test(config.url)) {
      delete config.headers['Authorization']
      return config
    }
    if (authenticationRegex.test(config.url)) {
      delete config.headers['Authorization']
      return config
    }
    if (
      /\/auth\/realms\/dele\/protocol\/openid-connect\/token/.test(config.url)
    ) {
      delete config.headers['Authorization']
      config.headers['content-type'] = 'application/x-www-form-urlencoded'
      return config
    }
    // connection with the new BE system
    if (
      (notificationsApiRegex.test(config.url) && newToken) ||
      (fcmApiRegex.test(config.url) && newToken) ||
      (rulesetApiRegex.test(config.url) && newToken) ||
      (customerMenagementApiCustomersRegex.test(config.url) && newToken) ||
      (customerMenagementApiTenantsRegex.test(config.url) && newToken) ||
      (customerManagementRoomsApiRegex.test(config.url) && newToken)
    ) {
      config.headers['Authorization'] = `Bearer ${await getNewToken()}`
      return config
    }
    if (token) {
      config.headers['Authorization'] = `Bearer ${await getToken()}`
      return config
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Add a response interceptor
Vue.axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    async function logout () {
      let user = await authService.getUser()
      if (user) {
        await store.dispatch('login/doLogout')
      }
    }
    const origRequest = error.config
    if (error.response) {
      if (error.response.status === 401) {
        // origRequest._retry = false
        if (/\/tokens\/renewals/.test(origRequest.url)) {
          await logout()
          return
        }
        if (
          notificationsApiRegex.test(origRequest.url) ||
          fcmApiRegex.test(origRequest.url) ||
          rulesetApiRegex.test(origRequest.url) ||
          customerMenagementApiCustomersRegex.test(origRequest.url) ||
          customerMenagementApiTenantsRegex.test(origRequest.url)
        ) {
          await refreshNewToken().then(async (response) => {
            if (response.access_token && response.refresh_token) {
              const user = jwtDecode(response.access_token)
              await authService.saveUserNew(user)
              await authService.saveNewToken(response.access_token)
              await authService.saveNewRefreshToken(response.refresh_token)
              await store.commit('login/SET_TOKEN', response.access_token)
              await store.commit('login/SET_MY_INFO_NEW', user)
            }
          })
        }
        try {
          const token = await store?.dispatch('login/refreshToken')
          if (token) {
            const newToken = await getToken()
            if (newToken) {
              origRequest._retry = true
              origRequest.headers['Authorization'] = `Bearer ${newToken}`
              await Vue.$socketApi.reconnect()
              return await Vue.axios(origRequest)
            }
          }
          // await logout()
          return
        } catch (err) {
          await logout()
          throw error
        }
      }
      if (error.response.status === 403) {
        if (
          customerMenagementApiCustomersRegex.test(origRequest.url) ||
          customerMenagementApiTenantsRegex.test(origRequest.url) ||
          fcmApiRegex.test(origRequest.url)
        ) {
          throw error
        }
        origRequest._retry = false
        await logout()
        throw error
      }
      if (error.response.status === 422) {
        origRequest._retry = false
        await logout()
        throw error
      }
      if (error.response.status === undefined) {
        return
      }
    }
    return Promise.reject(error)
  }
)

async function start () {
  const token = await authService.getToken()
  if (token) {
    let user = await authService.getUser()
    let userNew = await authService.getUserNew()
    try {
      if (!user) user = await store.dispatch('login/fetchMyInfo')
      if (user) {
        store.commit('login/LOGIN', true)
        store.commit('login/SET_MY_INFO', user)
        await store.dispatch('containers/fetchContainers')
      }
      if (userNew) {
        store.commit('login/SET_MY_INFO_NEW', userNew)
      }
      if (!userNew) {
        await store.dispatch('login/doLogout')
      }
    } catch (err) {
      console.log(err)
    }
    if (firebaseMessaging) {
      firebaseMessaging
        .getToken({ vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY })
        .then((currentToken) => {
          if (currentToken) {
            notificationService.saveFirebaseToken(currentToken)
            fcmSubscribe(currentToken, 'fall', 'subscribe')
            fcmSubscribe(currentToken, 'smart', 'subscribe')
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
  const clientId = `WebClient-${v4()}`
  setTimeout(() => {
    Vue.$socketApi = new SocketService(`${SOCKET_ROOT}/mqtt`, {
      clientId: clientId,
      username: 'Bearer',
      password: token,
      store: store
    })
  })
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App)
  }).$mount('#app')
}

start()
