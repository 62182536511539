export default {
  state: () => ({
    devices: [],
    deviceListeners: [],
    deviceInfo: {},
    registeredDevices: [],
    latestDeviceMessage: null,
    alarmMessages: [],
    warningMessages: [],
    latestOtherEventMessage: null,
    simulatedEvents: []
  })
}
