import Vue from 'vue'
import ServerMsgProcessor from '../../components/notifications/server_msgs'
import CrudMsgProcessor from '../../components/notifications/crud_msgs'
import { EVENT_RULESET_API_ROOT, CUSTOMER_MENAGEMENT_API_ROOT } from '@/config'
import { CustomMsg } from '@/components/notifications/_notifications'

/**
 * @param {boolean} active
 * @param {number} rulesetID
 * @param {number} clientID
 * @returns  {object} Rule
 */
export const toggleRuleForCustomer = async (active, rulesetID, clientID) => {
  try {
    const response = await Vue.axios.patch(
      `${EVENT_RULESET_API_ROOT}/api/rulesets/update/${rulesetID}`,
      { active: active },
      { headers: { clientid: clientID } }
    )
    if (response) {
      CrudMsgProcessor('successfullyUpdatedRuleset', response.data)
      return response.data
    }
  } catch (error) {
    ServerMsgProcessor('', error)
    return error
  }
}

/**
 * @returns  {object} Rule
 * @returns  {Array<object>} Ruleset for the selected customer
 */
export const getCommunityRulesets = async (communityId) => {
  try {
    const res = await Vue.axios.get(
      `${EVENT_RULESET_API_ROOT}/api/rulesets`,
      {
        headers: {
          clientid: communityId
        }
      }
    )
    return res.data
  } catch (error) {
    CustomMsg('Error: Something went wrong. Please try again later.')
    return Promise.reject(error)
  }
}

/**
 * @param {number} communityId
 * @param {number} page
 * @param {number} limit
 * @param {string} statusSubtype
 * @returns {object} Rule
 */
export const getApartmentRulesets = async (communityId, page, size = 10, statusSubtype, q = '') => {
  try {
    const res = await Vue.axios.get(
      `${CUSTOMER_MENAGEMENT_API_ROOT}/api/rooms/rulesets?page=${page}&limit=${size}&statusSubtype=${statusSubtype}&q=${q}`,
      {
        headers: {
          clientid: communityId
        }
      }
    )
    return res.data
  } catch (error) {
    CustomMsg('Error: Something went wrong. Please try again later.')
    return Promise.reject(error)
  }
}

export const postRuleset = async (communityId, rulesetDTO) => {
  try {
    const res = await Vue.axios.post(
      `${EVENT_RULESET_API_ROOT}/api/rulesets`,
      {
        roomId: rulesetDTO.roomId,
        active: rulesetDTO.active,
        label: rulesetDTO.rulesetLabel,
        sendSmartNotification: rulesetDTO.sendSmartNotification,
        conditions: {}
      },
      { headers: { clientid: communityId } }
    )
    return res.data
  } catch (error) {
    CustomMsg('Error: Something went wrong. Please try again later.')
    return Promise.reject(error)
  }
}

export const updateRule = async (rulesetId, customerId, body) => {
  try {
    const response = await Vue.axios.patch(
      `${EVENT_RULESET_API_ROOT}/api/rulesets/${rulesetId}`,
      body,
      { headers: { clientid: customerId } }
    )
    if (response) {
      return response.data
    }
  } catch (error) {
    CustomMsg('Error: Something went wrong. Please try again later.')
    return Promise.reject(error.response)
  }
}
