import { DateTime } from 'luxon'

export function minuteDiff (currTime, compareTime) {
  return Math.floor(((currTime - compareTime) / 1000) / 60)
}

export function formatISOStringAsFullDate (date) {
  const fullDate = DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)
  const fullTime = DateTime.fromISO(date).toLocaleString(
    DateTime.TIME_24_WITH_SHORT_OFFSET
  )
  if (fullDate !== 'Invalid DateTime' && fullTime !== 'Invalid DateTime') {
    return `${fullDate}, ${fullTime}`
  } else {
    return 'N/A'
  }
}
