<template>
  <div>
    <Icon
      :iconName="currentIcon.iconName"
      :size="size"
      :color="currentIcon.color"
      :isBootstrap="currentIcon.isBootstrap"
    />
    <div v-if="containsDescription">
      {{ currentIcon.description }}
    </div>
  </div>
</template>

<script>
import Icon from '@/components/elements/Icon'
import { wifiIcons } from '../../assets/icons/iconList'
export default {
  name: 'WifiIcon',
  components: {
    Icon
  },
  props: {
    wifiState: Number,
    size: String,
    containsDescription: Boolean
  },
  computed: {
    currentIcon: function () {
      switch (this.wifiState) {
        case 1:
        case 2:
        case 3:
        case 4:
          return wifiIcons[this.wifiState]
        default:
          return wifiIcons['default']
      }
    }
  }

}
</script>
