<template>
  <section class="confirm-dwell-time-reset">
    <div class="confirm-dwell-time-reset__content">
      <div class="confirm-dwell-time-reset__content__message">
        {{ $t('notification.confirmResetingDwellTime') }}
      </div>
      <div class="confirm-dwell-time-reset__content__buttons">
        <v-btn outlined @click.stop="cancelResettingAction">
          {{ $t('buttons.cancel') }}
        </v-btn>
        <v-btn
          outlined
          class="confirm-dwell-time-reset__content__buttons__reset-button"
          @click.stop="confirmResettingAction"
        >
          <span class="confirm-dwell-time-reset__content__buttons__reset-button__label">{{ $t('buttons.reset') }}</span>
        </v-btn>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ConfirmDwellTimeReset',
  props: ['cancelResettingAction', 'confirmResettingAction']
}
</script>
<style scoped>
.confirm-dwell-time-reset {
  min-height: calc(100vh - 2 * 200px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm-dwell-time-reset__content {
  width: 70%;
  font-size: 16px;
}
.confirm-dwell-time-reset__content__message {
  margin-bottom: 20px;
}
.confirm-dwell-time-reset__content__buttons {
  display: flex;
  justify-content: space-around;
}
.confirm-dwell-time-reset__content__buttons__reset-button {
  width: 100px;
  background-color: #ff0606;
  border-color: #ff0606;
}
.confirm-dwell-time-reset__content__buttons__reset-button__label {
  color: white;
}
</style>
