import api from '@/api'

export const editCommunityRule = ({ commit }, rule) => {
  commit('EDIT_COMMUNITY_RULE', rule)
}

export const getAndSetCommunityRulesets = async ({ commit }, id) => {
  try {
    const rulesets = await api.ruleset.getCommunityRulesets(id)
    commit('SET_COMMUNITY_RULESETS', rulesets)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const setCommunityRulesets = ({ commit }, rulesets) => {
  commit('SET_COMMUNITY_RULESETS', rulesets)
}

export const getAndSetApartments = ({ commit }) => {}

export const updateSingleApartment = ({ commit }, updatedRuleset) => {
  commit('UPDATE_SINGLE_APARTMENT', updatedRuleset)
}

export const setApartments = ({ commit }, apartments) => {
  commit('SET_APARTMENTS', apartments)
}

export const clearApartments = ({ commit }) => {
  commit('CLEAR_APARTMENTS')
}
