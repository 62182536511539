<template>
  <div>
    <Icon
      :iconName="currentIcon.icon"
      :size="size"
      :color="currentIcon.color"
      :is-bootstrap="currentIcon.isBootstrap"
    />
  </div>
</template>

<script>
import Icon from '@/components/elements/Icon'
import { iconList } from '../../assets/icons/iconList'

export default {
  name: 'LocationIcon',
  components: {
    Icon
  },
  props: {
    location: String,
    size: String
  },
  computed: {
    currentIcon: function () {
      return iconList[this.location] || iconList['room']
    }
  }
}
</script>
