const iconList = {
  shower: { icon: 'fa-bath', text: 'Bathroom' },
  person: { icon: 'fa-user-alt', text: 'Person' },
  triangleExclaim: { icon: 'exclamation-triangle', text: 'Warning' },
  circleExclaim: { icon: 'exclamation-circle', text: 'Warning' },
  cross: { icon: 'times', text: 'Close' },
  hikingHuman: { icon: 'hiking', text: 'Absence' },
  checkCircle: { icon: 'check-circle', text: 'Good' },
  presence: { icon: 'user-alt', text: 'Presence' },
  noPresence: { icon: 'user-slash', text: 'Absence' },
  facility: {
    icon: 'building',
    text: 'Building',
    isBootstrap: true,
    color: 'grey'
  },
  floor: {
    text: 'Building',
    icon: 'layers-half',
    color: 'grey',
    isBootstrap: true
  },
  room: {
    text: 'Room',
    icon: 'door-open',
    color: 'grey'
  },
  bedroom: {
    text: 'Bedroom',
    icon: 'bed',
    color: 'grey'
  },
  hallway: {
    text: 'Hallway',
    icon: 'dungeon',
    color: 'grey'
  },
  livingRoom: {
    text: 'Living Room',
    icon: 'couch',
    color: 'grey'
  },
  bathroom: {
    text: 'Bathroom',
    icon: 'bath',
    color: 'grey'
  },
  vBedroom: { icon: 'bedroom', text: 'Bedroom', vuetify: true },
  vBathroom: { icon: 'bathroom', text: 'Bathroom', vuetify: true },
  vHallway: { icon: 'hallway', text: 'Hallway', vuetify: true },
  vLivingRoom: { icon: 'livingRoom', text: 'Living Room', vuetify: true },
  vRoom: { icon: 'room', text: 'Room', vuetify: true },
  human: {
    icon: require('@/assets/images/icons/human.png'),
    text: 'Person'
  },
  fallingHuman: {
    icon: require('@/assets/images/icons/icon_fall.png'),
    text: 'Fall'
  },
  offline: {
    icon: require('@/assets/images/icons/grey_radar.png'),
    text: 'Offline'
  },
  WALABOT_HOME: {
    icon: 'atom',
    color: 'dodgerblue',
    text: 'WallaBot'
  },
  VAYYAR_HOME: {
    icon: 'atom',
    color: 'darkgrey',
    text: 'Vayyar Home'
  },
  batteryFull: {
    icon: 'battery-full',
    color: 'grey',
    text: 'Battery Full'
  },
  batteryMidFull: {
    icon: 'battery-three-quarters',
    color: 'grey',
    text: 'Battery ~75%'
  },
  batteryMidLess: {
    icon: 'battery-half',
    color: 'grey',
    text: 'Battery ~50%'
  },
  batteryMuchLess: {
    icon: 'battery-quarter',
    color: 'grey',
    text: 'Battery ~15%'
  },
  batteryEmpty: {
    icon: 'battery-empty',
    color: 'grey',
    text: 'Battery Empty'
  },
  alerts: {
    fallReported: { icon: require('@/assets/images/icons/icon_fall.png') },
    fallDetected: { icon: require('@/assets/images/icons/icon_fall.png') },
    inBedDuringDaytime: { icon: 'bed' },
    inBathroomDuringNightTime: { icon: 'bath' },
    outOfBed: { icon: 'bed' },
    outOfCoverageDuringInRoomOrNightTime: { icon: 'door-open' },
    outOfCoverageMoreThan8Hours: { icon: 'door-open' }
  },
  roomType: {
    Bedroom: { icon: 'bed' },
    Bathroom: { icon: 'bath' },
    Hallway: { icon: 'dungeon' }
  }
}

const wifiIcons = {
  1: {
    iconName: 'wifi1',
    color: 'grey',
    isBootstrap: true,
    description: 'Weak Signal'
  },
  2: {
    iconName: 'wifi2',
    color: 'grey',
    isBootstrap: true,
    description: 'Medium Signal'
  },
  3: {
    iconName: 'wifi2',
    color: 'grey',
    isBootstrap: true,
    description: 'Medium Signal'
  },
  4: {
    iconName: 'wifi',
    color: 'grey',
    isBootstrap: true,
    description: 'Full Strength'
  },
  default: {
    iconName: 'wifi-off',
    color: 'grey',
    isBootstrap: true,
    description: 'No Signal'
  }
}
export {
  iconList,
  wifiIcons
}
