let apiRoot = 'https://dbm-eu-app.dev.dele.ai/api/v4' // 'https://app.dev.dele.ai/api/v4' // 'https://app.dele.ai/api/v3' // 'http://localhost:8080/api/v3'
let socketRoot = 'wss://secure.mqtt.dev.dele.ai'
// new BE system
let authenticationApiRoot = 'https://dbm-eu-authentication-api.dev.dele.ai' // 'https://authentication-api.dev.dele.ai'
let keycloakApiRoot = 'https://dbm-eu-keycloak.dev.dele.ai' // 'https://keycloak.dev.dele.ai'
let fcmApiRoot = 'https://dbm-eu-fcm-api.dev.dele.ai' // 'https://fcm-api.dev.dele.ai'
let customerMenagementApiRoot = 'https://dbm-eu-customer-management-api.dev.dele.ai' // 'https://customer-management-api.dev.dele.ai'
let notificationApiRoot = 'https://dbm-eu-notifications-api.dev.dele.ai' // 'https://notifications-api.dev.dele.ai'
let eventRulesetApiRoot = 'https://dbm-eu-ruleset-api.dev.dele.ai' // 'https://ruleset-api.dev.dele.ai'
// TODO(Milica): Switch these before deploy!!

// if we've added .env files to the vue root
if (process.env.VUE_APP_API_ROOT) {
  apiRoot = process.env.VUE_APP_API_ROOT
}
if (process.env.VUE_APP_SOCKET_ROOT) {
  socketRoot = process.env.VUE_APP_SOCKET_ROOT
}

if (process.env.VUE_APP_AUTHENTICATION_API_ROOT) {
  authenticationApiRoot = process.env.VUE_APP_AUTHENTICATION_API_ROOT
}
if (process.env.VUE_APP_KEYCLOAK_API_ROOT) {
  keycloakApiRoot = process.env.VUE_APP_KEYCLOAK_API_ROOT
}
if (process.env.VUE_APP_FCM_API) {
  fcmApiRoot = process.env.VUE_APP_FCM_API
}
if (process.env.VUE_APP_CUSTOMER_MENAGEMENT) {
  customerMenagementApiRoot = process.env.VUE_APP_CUSTOMER_MENAGEMENT
}
if (process.env.VUE_APP_NOTIFICATION_API_ROOT) {
  notificationApiRoot = process.env.VUE_APP_NOTIFICATION_API_ROOT
}
if (process.env.VUE_APP_EVENT_RULESET) {
  eventRulesetApiRoot = process.env.VUE_APP_EVENT_RULESET
}

export const API_ROOT = apiRoot
export const SOCKET_ROOT = socketRoot

export const AUTHENTICATION_API_ROOT = authenticationApiRoot
export const KEYCLOAK_API_ROOT = keycloakApiRoot
export const FCM_API_ROOT = fcmApiRoot
export const CUSTOMER_MENAGEMENT_API_ROOT = customerMenagementApiRoot
export const NOTIFICATION_API_ROOT = notificationApiRoot
export const EVENT_RULESET_API_ROOT = eventRulesetApiRoot
