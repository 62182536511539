<template>
  <div>
    <v-snackbar
      class="position"
      :content-class="isItCustomNot"
      v-model="snackbar"
      absolute
      :vertical="true"
      right
      dark
      :timeout="timeout"
      :min-width="width"
    >
      {{ text }}
      <div v-if="link && $route.name !== 'Alarms'">
        <router-link  :to="'/' + link"><b-icon-alarm />
          <span class="sb-text" >
             See Event
          </span>
        </router-link>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="variant || 'green'"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import EventBus from '@/components/EventBus/EventBus'
export default {
  name: 'Notification',
  data: () => ({
    multiLine: true,
    snackbar: false,
    text: '',
    link: false,
    timeout: 5000,
    variant: null,
    width: 'auto'
  }),
  computed: {
    isItCustomNot () {
      return this.timeout === -1 ? 'custom' : ''
    }
  },
  methods: {
    makeToast (title, toaster = 'top-right', variant = null, text = '', solid = true, hideDelay = 700) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: solid,
        toaster: 'b-toaster-' + toaster,
        autoHideDelay: hideDelay
      })
    },
    makeSnackBar (title, toaster = 'top-right', variant = null, text = '', solid = true, hideDelay = 700, link = false, width = 'auto') {
      this.snackbar = true
      this.text = text
      this.link = link
      this.timeout = hideDelay
      this.variant = variant
      this.width = width
    }
  },
  mounted () {
    EventBus.$on('snackbar', (payload) => {
      const {
        title,
        text,
        variant,
        solid,
        location,
        hideDelay, link
      } = payload
      this.text = ''
      // this.makeToast(title, location, variant, text, solid, hideDelay)
      this.makeSnackBar(title, location, variant, text, solid, hideDelay, link)
    })
  }
}
</script>

<style scoped>
.position {
  z-index:999;
}
</style>
