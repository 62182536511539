export default {
  communityRuleForEdit (state) {
    return state.communityRuleForEdit
  },
  communityRulesets (state) {
    return state.communityRulesets
  },
  apartments (state) {
    return state.apartments
  }
}
