import { addTransformedRulesetsLabels, addTransformedRulesetLabel } from '../../../views/Notifications/utils'

export default {
  EDIT_COMMUNITY_RULE (state, ruleset) {
    state.communityRuleForEdit = ruleset ? addTransformedRulesetLabel(ruleset) : ruleset
  },
  SET_COMMUNITY_RULESETS (state, rulesets) {
    state.communityRulesets = addTransformedRulesetsLabels(rulesets)
  },
  SET_APARTMENTS (state, apartments) {
    state.apartments = apartments
  },
  UPDATE_SINGLE_APARTMENT (state, updatedRuleset) {
    const updatedApartment = { ...state.apartments.find(apartment => apartment.id === updatedRuleset.roomId), rulesets: [updatedRuleset] }
    state.apartments = state.apartments.map(apartment => {
      return apartment.id === updatedRuleset.roomId ? updatedApartment : apartment
    })
  },
  CLEAR_APARTMENTS (state) {
    state.apartments = []
  }
}
