<template>
  <article class="apartment">
    <header class="apartment__header">
      <span>{{ apartment.name }}</span>
      <b-form-checkbox
        class="colors apartment__header__toggle-button"
        name="daytime-check-button"
        v-model="isActive"
        @change="changeIsActive($event)"
        :disabled="loading"
        switch
      >
        {{ $t('notification.override') }}
      </b-form-checkbox>
    </header>
    <main class="apartment__content" :class="{ 'apartment__content_show': isActive }">
      <div class="apartment__content__notification">
        <span class='apartment__content__subtitle'>{{ $t('notification.notification') }}</span>
        <div class="apartment__content__notification__toggle-buttons">
          <b-form-checkbox
            class="colors"
            name="enabled-button"
            v-model="enabled"
            @change="changeEnabledValue($event)"
            switch
            :disabled="!isActive || loading"
          >
            {{ enabled ? 'On' : 'Off' }}
          </b-form-checkbox>
        </div>
      </div>
      <div v-if="!inBathroomForXTime">
        <span class='apartment__content__subtitle'>{{ $t('notification.timeframe') }}</span>
        <div class="apartment__content__timeframe__toggle-buttons">
          <b-form-checkbox
            class="colors apartment__content__timeframe__toggle-buttons__toggle-button"
            name="daytime-check-button"
            v-model="daytime"
            @change="changeDaytimeValue($event)"
            switch
            :disabled="!isActive || loading || !enabled"
          >
            {{ $t('notification.daytime') }}
          </b-form-checkbox>
          <b-form-checkbox
            class="colors"
            name="night-time-check-button"
            v-model="nightTime"
            @change="changeNightTimeValue($event)"
            switch
            :disabled="!isActive || loading || !enabled"
          >
            {{ $t('notification.nightTime') }}
          </b-form-checkbox>
        </div>
      </div>
      <div v-else>
        <div class='apartment__content__subtitle'>{{ $t('notification.bathroomDwell') }}</div>
        <div class='apartment__content__dwell-time'>
          <BathroomDwellTime :dwellTime="dwellTime" :community="false" :disabled="!isActive || !enabled" :setLoading="setLoading" :loading="loading" :rule="rule"/>
        </div>
      </div>
    </main>
  </article>
</template>

<script>
import { postRuleset, updateRule } from '@/api/ruleset'
import BathroomDwellTime from './BathroomDwellTime.vue'
import { RULESET, RULESET_CONDITION, DEFAULT_DWELL_DURATION } from '../../../views/Notifications/ruleset-enums'
import { mapActions } from 'vuex'

export default {
  components: { BathroomDwellTime },
  name: 'ApartmentCard',
  props: ['apartment', 'setNewSettings', 'communityRule', 'setLoading', 'loading'],
  data () {
    return {
      rule: this.getRule(this.apartment.rulesets),
      daytime: this.getPropertyValue(this.apartment.rulesets, RULESET_CONDITION.IS_DAYTIME),
      nightTime: this.getPropertyValue(this.apartment.rulesets, RULESET_CONDITION.IS_NIGHT_TIME),
      dwellTime: this.getDwellTime(this.apartment.rulesets),
      isActive: this.getActiveStatus(this.apartment.rulesets),
      enabled: this.getEnabledStatus(this.apartment.rulesets)
    }
  },
  computed: {
    inBathroomForXTime () {
      return this.communityRule.label === RULESET.DWELL_IN_BATHROOM
    }
  },
  methods: {
    ...mapActions({
      updateSingleApartment: 'notifications/updateSingleApartment'
    }),
    getRule (rulesetsArray) {
      return rulesetsArray?.length ? rulesetsArray[0] : undefined
    },
    getPropertyValue (rulesetsArray, conditionLabel) {
      return !!rulesetsArray?.length && !!rulesetsArray[0].conditions.find(condition => condition.label === conditionLabel)
    },
    getDwellTime (rulesetsArray) {
      if (rulesetsArray?.length && rulesetsArray[0].label === RULESET.DWELL_IN_BATHROOM) {
        const condition = rulesetsArray[0].conditions.find(condition => condition.label === RULESET_CONDITION.IS_DWELLING_IN_BATHROOM)
        return condition.dwellDuration
      }
      return DEFAULT_DWELL_DURATION
    },
    getActiveStatus (rulesetsArray) {
      return !!rulesetsArray?.length && rulesetsArray[0].active
    },
    getEnabledStatus (rulesetsArray) {
      return !!rulesetsArray?.length && rulesetsArray[0].enabled
    },
    async changeIsActive (isActiveValue) {
      try {
        this.setLoading(true)
        if (!this.apartment.rulesets.length) {
          const dto = {
            roomId: this.apartment.id,
            active: isActiveValue,
            rulesetLabel: this.communityRule.label,
            sendSmartNotification: false
          }
          const updatedRuleset = await postRuleset(this.apartment.clientId, dto)
          this.updateSingleApartment(updatedRuleset)
        } else {
          const updatedRuleset = await updateRule(
            this.apartment.rulesets[0].id,
            this.apartment.clientId,
            { active: isActiveValue }
          )
          this.updateSingleApartment(updatedRuleset)
        }
        this.setLoading(false)
      } catch (error) {
        this.isActive = !isActiveValue
        this.setLoading(false)
      }
    },
    async changeDaytimeValue (daytimeValue) {
      try {
        this.setLoading(true)
        const updatedRuleset = await updateRule(
          this.apartment.rulesets[0].id,
          this.apartment.clientId,
          { conditions: { daytime: daytimeValue } }
        )
        this.updateSingleApartment(updatedRuleset)
        this.setLoading(false)
      } catch (error) {
        this.daytime = !daytimeValue
        this.setLoading(false)
      }
    },
    async changeNightTimeValue (nightTimeValue) {
      try {
        this.setLoading(true)
        const updatedRuleset = await updateRule(
          this.apartment.rulesets[0].id,
          this.apartment.clientId,
          { conditions: { nighttime: nightTimeValue } }
        )
        this.updateSingleApartment(updatedRuleset)
        this.setLoading(false)
      } catch (error) {
        this.nightTime = !nightTimeValue
        this.setLoading(false)
      }
    },
    async changeEnabledValue (enabledValue) {
      try {
        this.setLoading(true)
        const updatedRuleset = await updateRule(
          this.apartment.rulesets[0].id,
          this.apartment.clientId,
          { enabled: enabledValue }
        )
        this.updateSingleApartment(updatedRuleset)
        this.setLoading(false)
      } catch (error) {
        this.enabled = !enabledValue
        this.setLoading(false)
      }
    }
  },
  watch: {
    apartment (newValue, oldValue) {
      this.rule = this.getRule(this.apartment.rulesets)
      this.daytime = this.getPropertyValue(newValue.rulesets, RULESET_CONDITION.IS_DAYTIME)
      this.nightTime = this.getPropertyValue(newValue.rulesets, RULESET_CONDITION.IS_NIGHT_TIME)
      this.dwellTime = this.getDwellTime(newValue.rulesets)
      this.isActive = this.getActiveStatus(newValue.rulesets)
      this.enabled = this.getEnabledStatus(newValue.rulesets)
    }
  }
}
</script>

<style scoped>
.apartment {
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.26);
  border-radius: 3px;
  margin: 20px 10px;
}
.apartment:first-child {
  margin-top: 5px;
}
.apartment__header {
  background: #f7f7f7;
  font-size: 15px;
  line-height: 24px;
  padding: 14px 20px;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  justify-content: space-between;
}
.apartment__header__toggle-button {
  font-size: 12px;
  font-weight: bold;
}
.apartment__content {
  padding: 20px 10px 10px 10px;
  line-height: 24px;
  display: none;
}
.apartment__content_show {
  display: flex;
}
.apartment__content__subtitle {
  text-align: left;
  display: block;
  color: rgba(0, 0, 0, 0.6);
  padding: 0 8px 5px 0;
}
.apartment__content__timeframe__toggle-buttons, .apartment__content__notification__toggle-buttons {
  display: flex;
  padding: 0 0 10px 0;
  font-weight: bold;
}
.apartment__content__timeframe__toggle-buttons__toggle-button {
  margin-right: 15px;
}
.apartment__content__notification {
  min-width: 45%;
  padding-left: 10px;
}
.apartment__content__dwell-time {
  display: flex;
  width: 90%;
}
.apartment__header__checkboxes {
  justify-content: space-between;
  display: flex;
  width: 180px;
}
</style>
