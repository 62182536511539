<template>
  <div class="sidebar-bg full-width" >
    <div class="pages-menu">
      <b-list-group class="sb-list">
        <div class="spacer"> </div>

        <router-link to="/alarms"><b-list-group-item><b-icon-alarm /> <span class="sb-text">{{$t('navigation.alarms')}}</span></b-list-group-item></router-link>

        <router-link to="/containers"><b-list-group-item ><b-icon-bullseye /><span class="sb-text"> {{$t('navigation.dashboard')}}</span></b-list-group-item></router-link>

<!--      <b-list-group-item v-if="isAdmin" v-b-toggle.collapse-3><b-icon-upload /><span class="sb-text" >Devices & Facility</span></b-list-group-item>
        <b-collapse id="collapse-3" style="color: white;" v-if="isAdmin">
          <b-card>
          </b-card>
        </b-collapse>

        <b-list-group-item v-if="isAdmin" v-b-toggle.collapse-2><b-icon-clipboard /><span class="sb-text" > Management</span></b-list-group-item>
        <b-collapse v-if="isAdmin" id="collapse-2" style="color: white;">
          <b-card>
          </b-card>
        </b-collapse>

        <b-list-group-item v-if="isAdmin" v-b-toggle.collapse-1><b-icon-gear /><span class="sb-text" > Settings</span></b-list-group-item>
        <b-collapse v-if="isAdmin" id="collapse-1" style="color: white;">
          <b-card>
          </b-card>
        </b-collapse>-->

        <router-link v-if="isAdmin || editorNonAdmin" to="/facility"><b-list-group-item><b-icon-building /> <span class="sb-text">{{$t('navigation.facility')}}</span></b-list-group-item></router-link>
        <router-link v-if="isAdmin || editorNonAdmin" to="/device-registration"><b-list-group-item><b-icon-grid /> <span class="sb-text">{{$t('navigation.deviceReg')}}</span></b-list-group-item></router-link>
        <router-link v-if="isAdmin || editorNonAdmin" to="/device-inventory"><b-list-group-item><b-icon-box-seam/> <span class="sb-text">{{$t('navigation.deviceInventory')}}</span></b-list-group-item></router-link>

        <router-link to="/alerts"><b-list-group-item><b-icon-box-seam /><span class="sb-text"> {{$t('navigation.alert')}}</span></b-list-group-item></router-link>
        <router-link v-if="higherAdmin" to="/audit"><b-list-group-item><b-icon-box-seam /><span class="sb-text"> {{$t('navigation.eventLog')}}</span></b-list-group-item></router-link>
        <router-link to="/user-management"><b-list-group-item><b-icon-person-badge /><span class="sb-text"> {{$t('navigation.userManagement')}}</span></b-list-group-item></router-link>

        <router-link v-if="higherAdmin"  to="/accounts-management"><b-list-group-item><b-icon-person-badge /><span class="sb-text"> {{$t('navigation.accountsManagement')}}</span></b-list-group-item></router-link>
        <router-link v-if="showNotification"  to="/notifications"><b-list-group-item><b-icon-bell /><span class="sb-text"> {{$t('navigation.notifications')}}</span></b-list-group-item></router-link>

        <router-link to="/pauses"><b-list-group-item><b-icon-person-badge /><span class="sb-text"> {{$t('navigation.pause')}}</span></b-list-group-item></router-link>
        <div class="spacer"> </div>
      </b-list-group>
      <div>
<!--        <v-btn color="primary"-->
<!--          elevation="2"-->
<!--          class="sidebar-button"-->
<!--          small-->
<!--          @click="redirectToPredictDashboard"-->
<!--          type="button"-->
<!--        >-->
<!--          {{$t('navigation.predictDashboard')}} <b-icon-box-arrow-up-right scale="0.9" style="margin: 0 2px 0 6px; position: relative; bottom: 1px;"/>-->
<!--        </v-btn>-->
        <b-list-group class="bottom-sidebar full-width sb-list" >
          <div @click="doLogout()">
            <b-list-group-item>
              <b-icon-box-arrow-in-left />
              <span class="sb-text"> {{$t('navigation.logout')}}</span>
            </b-list-group-item>
          </div>

<!--          <a href="https://delehealth.com/privacy-policy/" exact="true">-->
<!--            <b-list-group-item>-->
<!--              {{$t('navigation.privacyPolicy')}}-->
<!--            </b-list-group-item>-->
<!--          </a>-->

<!--          <a href="mailto:support@delehealth.com" target="_blank">-->
<!--            <b-list-group-item>-->
<!--              {{$t('navigation.contact')}}-->
<!--            </b-list-group-item>-->
<!--          </a>-->

        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Sidebar',
  computed: {
    ...mapGetters({
      getAlarmMessages: 'devices/getAlarmMessages',
      getWarningMessages: 'devices/getWarningMessages',
      isAdmin: 'login/isAdmin',
      myInfo: 'login/myInfo',
      getKnownAdminRoles: 'getKnownAdminRoles'
    }),
    higherAdmin: function () {
      return this.myInfo?.roles?.some(role => ['FULFILLER_ADMIN', 'SUPERADMIN'].includes(role))
    },
    hasAdminRole: function () {
      return this.myInfo?.roles?.some(role => this.getKnownAdminRoles?.includes(role))
    },
    editorNonAdmin: function () {
      return this.myInfo?.roles?.some(role => ['FULFILLER_USER'].includes(role))
    },
    totalAlarms: function () {
      return this.getAlarmMessages.length
    },
    totalWarnings: function () {
      return this.getWarningMessages.length
    },
    showNotification: function () {
      let currentURL = window.location.href
      let shouldShowNotification = (currentURL.includes('.dele.ai') || currentURL.includes('localhost')) && !currentURL.includes('dbm-us')
      return this.hasAdminRole && shouldShowNotification
    }
  },
  methods: {
    ...mapActions({
      doLogout: 'login/doLogout'
    })
    // redirectToPredictDashboard () {
    //   if (window.location.href.includes('dev.dele')) {
    //     window.open('https://app.powerbi.com/groups/d2c92291-efec-4a38-96d4-fd00e140e662/reports/993a5101-18dd-4fce-b7ac-ebdf935bf741/ReportSection')
    //   } else if (window.location.href.includes('sandbox.dele')) {
    //     window.open('https://app.powerbi.com/groups/d2c92291-efec-4a38-96d4-fd00e140e662/reports/0e62631d-b6e7-4646-bfd2-bdc4b45e6f01/ReportSection')
    //   } else {
    //     window.open('https://app.powerbi.com/groups/e95682e3-685c-4362-93b1-5e48d4cb8ed8/reports/4372b827-a062-4ee8-96c2-65f7ea3d1b41/ReportSection')
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/sidebar.scss';
.list-group-item {
  padding-top: 8px ;
  padding-bottom: 8px ;
  font-size: 15px;
}
</style>
