<template>
  <div class="slide-drawer-device-item">
    <v-row>
      <v-spacer v-if="areDeviceContainers" class="col-4"></v-spacer>
      <v-col class="col-8">
        <div
          class="rowstacked"
          v-bind:id="`device-${index}`"
          :style="[{ background: statusColor }]"
        >
          <div
            :class="[
              info.status.isOffline ? 'offline-overlay' : 'display-none'
            ]"
          >
            {{ $t('status.offline') }}
          </div>
          <div class="icons-wrapper">
            <div class="icon-padding">
              <PresenceIcon
                :presence="!info.status.isAbsence"
                size="110%"
                :containsDescription="true"
              />
              <small>
                {{
                  info.status.isAbsence
                    ? $t('status.noPresence')
                    : $t('status.presence')
                }}
              </small>
            </div>
            <div class="icon-padding">
              <ResidentIcon
                :residentFall="info.status.isFall"
                size="55px"
                :containsDescription="true"
              />
              <small>
                {{
                  info.status.isFall ? $t('status.fall') : $t('status.noFall')
                }}
              </small>
            </div>
          </div>
          <div class="stacked">
            <WifiIcon :wifiState="getCleanWifi(info.device)" size="23px" />
            <span class="small_icons_text"  v-if="getCleanWifi(info.device)">{{ Math.round(getCleanWifi(info.device))*100/4 }}%</span>
          </div>
          <b-tooltip
            v-if="isDeviceInfo"
            v-bind:target="`device-${index}`"
            placement="bottom"
          >
            <div v-if="!!getDeviceType(info.device)">
              {{ $t('features.type') }}:
              <strong>{{ getDeviceType(info.device) }}</strong
              ><br />
            </div>
            {{ $t('features.wifi') }}:
            <strong>{{ getCleanWifi(info.device) ? ((Math.round(getCleanWifi(info.device))*100/4) + "%") : 'N/A' }}</strong>
            <br />
            {{ $t('features.device') }}:
            <strong>{{ info.device.deviceTypeId }}</strong>
            <br />
            {{ $t('features.sku') }}:
            <strong>{{ info.device.metadata.serialNumber || info.device.metadata.SerialNumber }}</strong>
          </b-tooltip>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import WifiIcon from '@/components/elements/WifiIcon'
import ResidentIcon from '@/components/elements/ResidentIcon'
import PresenceIcon from '@/components/elements/PresenceIcon'
import statusColorMap from '@/utils/statusColorMap'
import { deviceTypeCodes } from '@/utils/deviceTypeCodes'

export default {
  name: 'SlideDrawerDeviceItem',
  components: {
    WifiIcon,
    ResidentIcon,
    PresenceIcon
  },
  props: {
    id: String,
    index: Number,
    info: Object,
    areDeviceContainers: Boolean
  },
  computed: {
    ...mapGetters({
      getDeviceInfoById: 'devices/getDeviceInfoById'
    }),
    convertedDate () {
      return 0
    },
    status () {
      const { isFall, isOffline, isAbsence } = this.info.status
      if (isFall) {
        return 'fallDetected'
      } else if (isOffline) {
        return 'noHeartbeat'
      } else if (isAbsence) {
        return 'noPresence'
      } else {
        return 'ok'
      }
    },
    statusColor () {
      return statusColorMap[this.status]
    },
    isDeviceInfo () {
      return !!this.info?.device
    },
    isDeviceType () {
      return !!this.info?.device?.deviceTypeId
    },
    roomType () {
      return this.info?.device?.metadata?.roomType || 'room'
    }
  },
  methods: {
    getDeviceType (device) {
      const deviceType = deviceTypeCodes[device.deviceTypeId].type
      return this.$t(`deviceType.${deviceType}`)
    },
    getDeviceTemp (device) {
      return device?.status?.TEMPERATURE?.temperature
    },
    getCleanWifi (device) {
      const WifiInstant = this.info?.device?.status?.WIFI?.instant
      if (WifiInstant) {
        const lastHeard = moment(WifiInstant)
        const beating = moment().diff(lastHeard, 'seconds') < 125
        if (beating) return this.info?.device?.status?.WIFI?.signalStrength
      }
      // temporary fix for unreliable device status WIFI property in dev data
      // It should really return the latest WIFI signalStrength no matter if status is anything else
      return this.status === 'noHeartbeat' ? 0 : this.info?.device?.status.WIFI?.signalStrength ? this.info?.device?.status.WIFI?.signalStrength : 4
    }
    // toggleCalendar(shouldShow) {
    //   this.showCal = shouldShow
    // },
  }
}
</script>
