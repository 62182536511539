export const crudMsg = {
  Container: {
    createSuccess: inp =>
      inp
        ? `Successfully Created ${inp}!`
        : 'Successfully Added to Facility Structure',
    updateSuccess: inp =>
      inp ? `Successfully Updated ${inp}!` : 'Updated Facility Structure',
    generalError: 'Could Not Connect',
    NoConnection: 'Connection Issue',
    amplify: inp => `${inp} PointClickCare Facilities`,
    removeSuccess: inp =>
      inp
        ? `Successfully Removed ${inp}!`
        : 'Successfully Removed Containing Element'
  },
  Device: {
    addSuccess: inp =>
      inp ? `Successfully Added ${inp}!` : 'Successfully Added Device',
    deleteSuccess: inp =>
      inp ? `Successfully Deleted ${inp}!` : 'Successfully Deleted Device',
    updateSuccess: inp =>
      inp ? `Added ${inp} to Facility Structure` : 'Updated Facility Structure',
    uploadSuccess: 'Successfully Uploaded Devices',
    removeSuccess: inp =>
      inp ? `Successfully Removed ${inp}!` : 'Successfully Removed Device',
    notificationReceived: inp => inp || 'New alert occurred!'
  },
  User: {
    createUser: inp =>
      inp ? `Successfully Created ${inp}!` : 'Successfully Created New User!',
    editedRole: inp => inp && `Successfully ${inp}!`,
    deletedUser: inp =>
      inp ? `Successfully Deleted ${inp}!` : 'Successfully Deleted User!',
    createUserErr: inp =>
      inp ? `Problem Creating ${inp}!` : 'Problem Creating New User!',
    editedRoleErr: inp => inp && `Error with ${inp}!`,
    deletedUserErr: inp =>
      inp ? `Problem Deleting ${inp}!` : 'Problem Deleting User!',
    editCurrUser: inp =>
      inp ? `Successfully Edited ${inp}!` : 'Successfully Edited User!',
    editCurrUserErr: inp =>
      inp ? `Problem Editing ${inp}!` : 'Problem Editing User!',
    invalidFirebaseToken: () => 'No firebase registration token available.',
    updatedRuleset: inp => inp ? `Rule "${inp.description}" is successfully turned ${inp.active ? 'ON' : 'OFF'}` : 'Successfully updated.'
  }
}

export const deviceMsg = {
  incoming: (inp) => `${inp}`,
  negativeEvent: (inp) => `${inp}`,
  simulateSoftAlertError: () => 'Error - Simulate Smart Alert'
}

export const authMsg = {
  Login: {
    fail: 'Incorrect Login Email/Password Combination',
    success: (inp) => `Successfully Logged in as ${inp}!`,
    error: 'Invalid client credentials'
  },
  Logout: {
    fail: '',
    success: 'Successfully Logged Out'
  },
  Signup: {
    success: inp => `Successfully Registered ${inp}!`,
    emailError: 'Email should resemble: yourname@customer.delehealth.com',
    alreadyExists: inp => (inp ? `${inp}` : 'User already exists!')
  },
  Common: {
    password: 'Password is Required!',
    email: 'Email is Invalid',
    generalErr: 'Authentication Error'
  }
}

export const serverMsg = {
  Server: {
    unauthorized: inp => (inp ? `Unauthorized - ${inp}` : 'Unauthorized user'),
    generalError: inp => (inp ? `Server Error - ${inp}` : 'Server Error'),
    connIssueWithFcm: (inp) => inp || 'Connection issue with Firebase',
    NoConnection: inp =>
      inp ? `Connection Issue - ${inp}` : 'Connection Issue',
    FourOhOne: 'Error Creating Element...'
  }
}
