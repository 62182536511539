import Vue from 'vue'
import * as helpers from '../helpers'
import CrudMsgProcessor from '@/components/notifications/crud_msgs'
import ServerMsgProcessor from '@/components/notifications/server_msgs'

export const getRootContainers = async id => {
  let url = `${helpers.buildBaseUrl()}/container-roots`
  if (id) url = `${url}/${id}`

  let response, data
  // eslint-disable-next-line no-useless-catch
  try {
    response = await Vue.axios.get(url)
    if (response) data = response.data
    if (data?.items) data = data.items
  } catch (err) {
    handleErr(err)
    throw err
  }
  return data
}

export const getContainers = async id => {
  let url = `${helpers.buildBaseUrl()}/container-trees`
  if (id) url = `${url}/${id}`
  let response, data
  // eslint-disable-next-line no-useless-catch
  try {
    response = await Vue.axios.get(url)
    if (response) data = response.data
    if (data.items) data = data.items
  } catch (err) {
    handleErr(err)
    throw err
  }
  return data
}

export const getContainerAsList = async id => {
  let url = `${helpers.buildBaseUrl()}/container-trees-by-container-id`
  if (id) url = `${url}/${id}`

  let response, data
  // eslint-disable-next-line no-useless-catch
  try {
    response = await Vue.axios.get(url)
    if (response) data = response.data
  } catch (err) {
    handleErr(err)
    throw err
  }
  return data
}

export const getContainer = async id => {
  let url = `${helpers.buildBaseUrl()}/containers`
  if (id) url = `${url}/${id}`
  let response, data
  // eslint-disable-next-line no-useless-catch
  try {
    response = await Vue.axios.get(url)
    if (response) data = response.data
    if (data.items) data = data.items
  } catch (err) {
    handleErr(err)
    throw err
  }
  return data
}

export const putContainer = async (container, msgType, updatedObj, name) => {
  const id = container.id
  const url = `${helpers.buildBaseUrl()}/container-trees/${id}`
  let response, data
  // eslint-disable-next-line no-useless-catch
  try {
    response = await Vue.axios.put(url, { root: container })
    if (response) {
      data = response.data
      CrudMsgProcessor(msgType, name ?? updatedObj?.name ?? updatedObj[0]?.metadata?.SerialNumber ?? updatedObj?.metadata?.SerialNumber)
    }
  } catch (err) {
    handleErr(err)
    throw err
  }
  return data
}

export const postContainer = async (payload) => {
  const url = `${helpers.buildBaseUrl()}/container-trees`
  let response, data
  // eslint-disable-next-line no-useless-catch
  try {
    console.log('in api calling post container-trees')
    response = await Vue.axios.post(url, { root: payload[0] })
    if (response) {
      data = [response.data]
      CrudMsgProcessor('container_create', payload?.name)
    }
  } catch (err) {
    handleErr(err)
    throw err
  }
  return data
}

export const downloadAmplifyTree = async (rootContainerId) => {
  const url = `${helpers.buildBaseUrl()}/pointclickcare/amplify/device-registrations/${rootContainerId}`
  let response, data
  try {
    response = await Vue.axios.get(url, { headers: { Accept: 'application/csv' } })
    if (response) {
      data = response.data
      CrudMsgProcessor('amplifyDL', 'Downloading')
    }
  } catch (err) {
    handleErr(err)
    throw err
  }
  return data
}

export const importAmplifyTree = async (payload) => {
  const url = `${helpers.buildBaseUrl()}/pointclickcare/amplify/container-trees`
  const isUnits = (payload === 'units')
  let response, data
  try {
    response = await Vue.axios.post(url, { usingUnits: isUnits })
    // might need to do some tweaks to mirage so it doesn't return like this
    if (response) {
      data = response.data
      CrudMsgProcessor('amplifyDL', 'Importing')
    }
  } catch (err) {
    handleErr(err)
    throw err
  }
  return data
}

function handleErr (err) {
  err?.response?.status ? CrudMsgProcessor('err', err) : ServerMsgProcessor('', err)
}
