export const appendNestedInfo = containersObj => {
  const updatedContainersObj = containersObj
  for (let containerId of Object.keys(containersObj)) {
    updatedContainersObj[containerId].nestedDeviceIds =
      collectDeviceIds(containerId, containersObj, [], true)
    updatedContainersObj[containerId].nestedContainerIds =
      collectContainerIds(containerId, containersObj, [])
  }
  return updatedContainersObj
}

const collectDeviceIds = (
  containerId,
  allContainers,
  collectedDeviceIds,
  isTopLevel
) => {
  const thisContainer = allContainers[containerId]
  // Avoid adding top level device ids
  const deviceIdsToAdd = isTopLevel ? [] : thisContainer.deviceIds
  // Merge device ids collected thus far with this level's device ids
  collectedDeviceIds = [...collectedDeviceIds, ...deviceIdsToAdd]
  for (let childId of thisContainer.childrenIds) {
    // Call same function recursively for each child container
    collectedDeviceIds = collectDeviceIds(childId, allContainers, collectedDeviceIds, false)
  }
  return collectedDeviceIds
}

const collectContainerIds = (
  containerId,
  allContainers,
  collectedContainerIds
) => {
  const thisContainer = allContainers[containerId]
  // Merge container ids collected thus far with this level's container ids
  collectedContainerIds = [
    ...collectedContainerIds,
    ...thisContainer.childrenIds
  ]
  for (let childId of thisContainer.childrenIds) {
    // Call same function recursively for each child container
    collectedContainerIds = collectContainerIds(childId, allContainers, collectedContainerIds)
  }
  return collectedContainerIds
}
