import config from './config'
import * as actions from './actions'
import getters from './getters'
import mutations from './mutations'
import containers from './modules/containers'
import devices from './modules/devices'
import login from './modules/login'
import notifications from './modules/notifications'

const state = config.state()
const debug = process.env.NODE_ENV !== 'production'

export default {
  state,
  getters,
  actions,
  mutations,
  modules: {
    containers,
    devices,
    login,
    notifications
  },
  strict: debug
}
