import { cloneDeep, isEmpty } from 'lodash'
import TreeModel from 'tree-model-improved'

const treeModel = new TreeModel({
  modelComparatorFn: (a, b) => a.name?.toLowerCase() > b.name?.toLowerCase()
})

export default {
  getContainers (state, getters) {
    return state.containers
  },
  getRoots (state, getters) {
    return state.roots
  },
  getRootContainer (state, getters) {
    return state.currentRoot
  },
  getContainerTreeAsObject (state, getters) {
    return state.containerTreeAsObject
  },
  getSelectedContainer (state, getters) {
    if (
      state.containers &&
      state.selectedContainer &&
      !isEmpty(state.selectedContainer)
    ) {
      const tree = treeModel.parse(cloneDeep(state.containers))
      const found = tree.first(node => node.model.id === state.selectedContainer)
      return found.model
    }
    return {}
  },
  getContainerById: state => id => {
    return state.containerTreeAsObject[id]
  },
  getContainerStatusById: (state, getters, rootState, rootGetters) => id => {
    const { deviceIds, nestedDeviceIds } = state.containerTreeAsObject[id]
    const allDeviceIds = [...deviceIds, ...nestedDeviceIds]
    // Todo: Tech Debt - I've noticed spreading either of the above when undefined throws an error (common when there are no devices in use)
    // Todo: verify the above is fixed
    if (!allDeviceIds.length) return null
    const getDeviceInfoById = id => rootGetters['devices/getDeviceInfoById'](id)
    if (allDeviceIds.some(deviceId => getDeviceInfoById(deviceId)?.status?.isFall)) {
      return 'fallDetected'
    }
    if (allDeviceIds.some(deviceId => getDeviceInfoById(deviceId)?.status?.isOffline)) {
      return 'noHeartbeat'
    }
    if (allDeviceIds.every(deviceId => getDeviceInfoById(deviceId)?.status?.isAbsence)) {
      return 'noPresence'
    }
    return 'ok'
  },
  getSliderState (state, getters) {
    return state.showSlideDrawer
  },
  getImportingState (state) {
    return state.importStarted
  }
}
