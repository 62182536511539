<template>
  <div class="slide-drawer-container-item">
    <v-row class="item-wrapper">
      <v-col class="location-wrapper col-4 d-flex align-items-center">
        <div class="icon-padding">
          <LocationIcon
            v-if="roomIcon"
            class="location-icon"
            :location="roomIcon"
            size="12px"
          />
          <div class="pt-2">{{ containerName }}</div>
        </div>
      </v-col>
      <v-col class="status-wrapper col-8">
        <div :id="`container-item-${id}`">
          <div
            :style="[{ background: statusColor }]"
            class="rowstacked"
            :class="!isDeviceContainer && 'cursor-pointer'"
            @click="handleItemClick"
          >
            <div
              :class="[
                info.status.isOffline ? 'offline-overlay' : 'display-none',
                !isDeviceContainer && 'cursor-pointer'
              ]"
            >
              {{ $t('status.offline') }}
            </div>
            <div class="icons-wrapper">
              <div class="icon-padding">
                <PresenceIcon
                  :presence="!info.status.isAbsence"
                  size="110%"
                  :containsDescription="true"
                />
                <small>
                  {{
                    info.status.isAbsence
                      ? $t('status.noPresence')
                      : $t('status.presence')
                  }}
                </small>
              </div>
              <div class="icon-padding">
                <ResidentIcon
                  :residentFall="info.status.isFall"
                  size="55px"
                  :containsDescription="true"
                />
                <small>
                  {{
                    info.status.isFall ? $t('status.fall') : $t('status.noFall')
                  }}
                </small>
              </div>
            </div>
            <div class="stacked">
              <WifiIcon :wifiState="getCleanWifi" size="23px"/>
              <span class="small_icons_text" v-if="getCleanWifi">{{ Math.round(getCleanWifi) * 100 / 4 }}%</span>
            </div>
            <b-tooltip
              v-if="showTooltip"
              :target="`container-item-${id}`"
              placement="bottom"
            >
              <div v-if="!!getDeviceType()">
                {{ $t('features.type') }}: <strong>{{ getDeviceType() }}</strong
              ><br/>
              </div>
              {{ $t('features.wifi') }}:
              <strong>{{ getCleanWifi ? ((Math.round(getCleanWifi) * 100 / 4) + "%") : 'N/A' }}</strong>
              <br/>
              {{ $t('features.device') }}:
              <strong>{{ info.device.deviceTypeId }}</strong>
              <br/>
              {{ $t('features.sku') }}:
              <strong>{{ info.device.metadata.serialNumber || info.device.metadata.SerialNumber }}</strong>
            </b-tooltip>
          </div>
        </div>
      </v-col>
    </v-row>
    <ResolutionButton v-if="false" :status="status" :resolveEvent="resolveEvent" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import WifiIcon from '@/components/elements/WifiIcon'
import LocationIcon from '@/components/elements/LocationIcon'
import ResidentIcon from '@/components/elements/ResidentIcon'
import PresenceIcon from '@/components/elements/PresenceIcon'
import statusColorMap from '@/utils/statusColorMap'
import { deviceTypeCodes } from '@/utils/deviceTypeCodes'
import moment from 'moment'
import ResolutionButton from '../resolutions/ResolutionButton'

export default {
  name: 'SlideDrawerContainerItem',
  components: {
    WifiIcon,
    LocationIcon,
    ResidentIcon,
    PresenceIcon,
    ResolutionButton
  },
  props: {
    id: String,
    index: Number,
    info: Object,
    isDeviceContainer: Boolean
  },
  data () {
    return {
      linkToParent: '',
      dialog: false,
      selectedReso: '',
      resoCodes: [
        {
          id: 'CONFIRMED_WITH_INJURY',
          name: 'Confirmed w/ Injury'
        },
        {
          id: 'CONFIRMED_WITHOUT_INJURY',
          name: 'Confirmed w/o Injury'
        },
        {
          id: 'REJECTED',
          name: 'Rejected'
        },
        {
          id: 'SIMULATED',
          name: 'Simulated'
        },
        {
          id: 'UNCONFIRMED',
          name: 'Unconfirmed'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getContainerInfo: 'getContainerInfo'
    }),
    status () {
      const {
        isFall,
        isAbsence,
        isOffline
      } = this.info?.status
      if (isFall) {
        return 'fallDetected'
      } else if (isOffline) {
        return 'noHeartbeat'
      } else if (isAbsence) {
        return 'noPresence'
      } else {
        return 'ok'
      }
    },
    statusColor () {
      return statusColorMap[this.status]
    },
    containerName () {
      const container = this.getContainerInfo[this.id]
      const parent = this.getContainerInfo[container?.parentId]
      if (!this.isDeviceContainer && container?.name && parent?.name) {
        return parent.name + ' / ' + container.name
      }
      return container.name
    },
    roomIcon () {
      return this.getContainerInfo[this.id]?.renderingMetadata?.iconName
    },
    getCleanWifi () {
      const WifiInstant = this.info?.device?.status?.WIFI?.instant

      if (WifiInstant) {
        const lastHeard = moment(WifiInstant)
        const beating = moment().diff(lastHeard, 'seconds') < 125

        if (beating) return this.info?.device?.status?.WIFI?.signalStrength
      }
      // temporary fix for unreliable device status WIFI property in dev data
      // It should really return the latest WIFI signalStrength no matter if status is anything else
      return this.status === 'noHeartbeat' ? 0 : this.info?.device?.status.WIFI?.signalStrength ? this.info?.device?.status.WIFI?.signalStrength : 4
    }
  },
  methods: {
    ...mapActions({
      toggleSlideDrawer: 'containers/toggleSlideDrawer',
      clearSelectedContainer: 'containers/clearSelectedContainer',
      setSelectedContainer: 'containers/setSelectedContainer',
      doResolveEvent: 'devices/doResolveEvent'
    }),
    async resolveEvent () {
      await this.doResolveEvent({
        code: this.selectedReso,
        id: this.info?.device?.status?.FALL?.precipitatingEventId
      })
    },
    setLinkToParent () {
      if (!this.isDeviceContainer) {
        this.linkToParent = '/containers/' + this.info?.container?.parentId
      }
    },
    handleItemClick () {
      if (!this.isDeviceContainer) {
        this.setSelectedContainer(this.info?.container?.parentId)
      }
    },
    getDeviceType () {
      const deviceType = deviceTypeCodes[this.info?.device?.deviceTypeId]?.type
      if (deviceType) return this.$t(`deviceType.${deviceType}`)
    },
    getDeviceTemp () {
      return this.info?.device?.status?.TEMPERATURE?.temperature
    },
    showTooltip () {
      return !!this.info?.device
    }
  },
  mounted () {
    this.setLinkToParent()
  }
}
</script>
