import firebase from 'firebase/app'
import 'firebase/messaging'
import { formatISOStringAsFullDate } from '@/utils/timeFunctions'
import CrudMsgProcessor from '@/components/notifications/crud_msgs'
import { store } from '../../main'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASURMENT_ID
}
firebase.initializeApp(firebaseConfig)

let messaging = null

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()

  messaging.onMessage((payload) => {
    if (payload.data.status === 'staffSimulated') {
      const date = parseInt(payload.data.startTimestamp)
      store.dispatch('devices/addNewSimulatedEvent', {
        ...payload.data,
        startTimestamp: formatISOStringAsFullDate(new Date(date).toISOString())
      })
    }
    CrudMsgProcessor(
      'notificationReceived',
      `${payload.notification.title} ${payload.notification.body}`
    )
  })
}

export default messaging
