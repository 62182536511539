<template>
  <div
    class="bg-overlay"
    v-bind:class="{ 'show-bg': getSliderState || communityRuleForEdit }"
    v-on:click="localToggle()"
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'BgOverlay',
  methods: {
    localToggle: function () {
      if (this.getSliderState) {
        this.clearSelectedContainer()
      }
      this.toggleSlideDrawer(!this.getSliderState)
    },
    ...mapActions({
      toggleSlideDrawer: 'containers/toggleSlideDrawer',
      clearSelectedContainer: 'containers/clearSelectedContainer',
      editCommunityRule: 'notifications/editCommunityRule'
    })
  },
  computed: {
    ...mapGetters({
      getSliderState: 'containers/getSliderState',
      communityRuleForEdit: 'notifications/communityRuleForEdit'
    })
  }
}
</script>
<style>
.bg-overlay {
    opacity: 0;
    position: absolute;
    width: 0px;
    height: 100%;;
    z-index: -1;
    top: 0;
    left: 0;
    background: black;
    transition: all 0.4s ease;
}
.show-bg {
    width: 100%;
    height: 100%;
    z-index: 100;
    transition: all 0.4s ease;
    opacity: 0.4;
    transition-timing-function: cubic-bezier(0.42,0,0.58,1)
}
</style>
