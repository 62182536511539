import api from '@/api'
import Vue from 'vue'
import { mapKeys, merge, omit, isEmpty, cloneDeep } from 'lodash'
// import DeviceMsgProcessor from '@/components/notifications/device_msgs'
import { minuteDiff } from '@/utils/timeFunctions'
import { fetchContainers } from '@/store/modules/containers/actions'
import { getMyIds } from '@/api/helpers'
import { resolveEvent, postDemoEvent } from '@/api/devices'

export const fetchDevices = async ({ commit, dispatch }, data) => {
  try {
    const response = await api.devices.getDevices()
    commit('SET_DEVICES', response.data || [])
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const deleteDevice = async ({ commit, dispatch }, data) => {
  try {
    const response = await api.devices.deleteDevice(data)
    console.log(response)
    // commit('SET_DEVICES', response.data)
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const addDeviceToInventory = async ({ commit, dispatch }, data) => {
  try {
    const response = await api.devices.addDeviceToInventory(data)
    console.log(response)
    return response
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const fetchRegisteredDevices = async ({ commit, state }) => {
  const response = await api.devices.getRegisteredDevices()
  const data = cloneDeep(response.data)
  commit('SET_REGISTERED_DEVICES', data || [])
  await setDeviceInfo({ commit, state }, { data })
}

export const setDeviceInfo = async ({ commit, state }, { data, removedDeviceId }) => {
  if (data && !isEmpty(data)) {
    // Add status properties to each device assuming all is ok
    const devicesWithStatuses = data.map(device => {
      return {
        ...device,
        status: checkDeviceStatus(device.device, commit)
      }
    })
    // Normalize backend data into flat object structure with id as key
    const normalizedDevices = mapKeys(devicesWithStatuses, 'device.id')
    // Merge new state with current state
    const mergedDevices = merge(
      normalizedDevices,
      // Existing devices overwrite new data in order to keep status info
      omit(state.deviceInfo, removedDeviceId) // If a device was deregistered, remove status info from state
    )

    commit('SET_DEVICE_INFO', mergedDevices)
  } else {
    // Clear State when Customer Switches and Blank
    commit('SET_DEVICE_INFO', [])
  }
}

export const checkDeviceStatus = (device, commit) => {
  const currTs = Date.now()
  const deviceFallTs = Date.parse(device.status?.FALL?.instant)
  const reportFall = device.status?.FALL?.action === 'report-fall' && device.status?.FALL?.past
  const deviceAbsence = !device.status?.PRESENCE?.presence // Opposite of Presence is Absence
  // const devicePresenceTs = Date.parse(device.status?.PRESENCE?.instant)  // Potentially useful for presence ts
  if (device.status?.WIFI) {
    const strength = parseFloat(device.status.WIFI.signalStrength)
    if (strength < 0.2) {
      device.status.WIFI.signalStrength = 0
    } else if (strength < 0.4) {
      device.status.WIFI.signalStrength = 1
    } else if (strength < 0.6) {
      device.status.WIFI.signalStrength = 2
    } else if (strength < 0.8) {
      device.status.WIFI.signalStrength = 3
    } else {
      device.status.WIFI.signalStrength = 4
    }
  }

  let data = {}
  data.isOffline = !device.status?.HEARTBEAT?.online
  data.isFall = minuteDiff(currTs, deviceFallTs) < 10 && reportFall
  data.isAbsence = deviceAbsence
  if (data.isFall) {
    console.log(
      'Time Remaining on Fall: ',
      Math.floor((10 * 1000 * 60 - (currTs - deviceFallTs)) / 1000 / 60) + ' Minutes'
    )
    // Clear after (Ten Minutes minus time difference from Now until when the previous fall occurred)
    setTimeout(() => commit('CLEAR_FALL_EVENT', device), (10 * 1000 * 60 - (currTs - deviceFallTs)))
  }
  return data
}

export const listenToDevices = ({ commit, state, rootState }) => {
  console.log(rootState)
  console.log('listenToDevices')
  if (rootState?.login?.myIds) {
    const { CUSTOMER, FULFILLER } = rootState?.login?.myIds
    const topicString = `DEFAULT/${FULFILLER}/${CUSTOMER}/#`
    console.log('about to listen for topic: ', topicString)
    let data
    Vue.$socketApi.initListener(topicString, data)
    console.log('listening to topic: ', topicString)
  }
}

export const addMessageListener = ({ commit, state }, data) => {
  if (data) {
    commit('NEW_DEVICE_LISTENER', data.message)
  }
}
export const handleNewSocketMessage = async ({ commit, state }, data) => {
  console.log('data: ', data)
  if (data?.message) {
    if (data.message.meta?.event?.will) {
      console.log('Dropping message event: will')
      console.log(data.message.meta?.event?.will)
      return
    }
    console.log(`Receiving ${data.message?.meta?.event?.did} message for ${data.message?.data?.container?.name}`)
    console.log('Full Event Message: ', data)
    // TODO: Undo this this when we're ready to have toast notifications in the browser
    // DeviceMsgProcessor(data.message.meta?.event?.did, data.message?.data?.container?.fullyQualifiedName, 'alarms')
    commit('SET_LATEST_DEVICE_MESSAGE', data.message)
    commit('NEW_DEVICE_EVENT', data.message)
    if (data.message?.meta?.event?.did === 'report-fall') {
      // Clear fall status after 10 minutes
      setTimeout(() => commit('CLEAR_FALL_EVENT', data.message), 1000 * 60 * 10)
    } else if (data.message?.meta?.event?.did === 'import-amplify-org') {
      await fetchContainers()
    }
  }
}

export const doUpload = async ({ commit, state }, { data, path }) => {
  try {
    const response = await api.devices.upload(data, path)
    return response?.data
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const doResolveEvent = async ({ commit, state }, data) => {
  const myIds = getMyIds()
  let params = {}
  if (myIds?.CUSTOMER) {
    params.customerId = myIds.CUSTOMER[0]
    params.resolutionCode = data.code
  }
  console.log('resolve event', params)
  if (params.customerId) {
    try {
      let response = await resolveEvent(params, data.id)
      return response
    } catch (err) {
      throw err
    }
  }
}

export const simulateFall = async ({ getters }, { customerId, fulfillerId }) => {
  try {
    const devices = getters.getRegisteredDevices.map(d => d.device)
    const deviceId = devices[Math.floor(Math.random() * devices.length)].id
    const body = { phenomenon: 'FALL', deviceId, customerId, fulfillerId }
    await postDemoEvent(body)
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const addNewSimulatedEvent = ({ commit }, alert) => {
  commit('SET_SIMULATED_EVENT', alert)
}
