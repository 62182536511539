import config from './config'
import * as actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = config.state()

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
